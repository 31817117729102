angular.module('deitz')
    .controller('addEmailTemplateTypeVariableController',
            ['$scope',
            '$rootScope',           
            'codeFactory',
            '$http',
            '$state',
            'Notification',
            'apiUrl',
            function ($scope,
            $rootScope,              
            codeFactory, 
            $http,
            $state,
            Notification,
            apiUrl) {
        $scope.page_title = $state.current.data.pageTitle;
        
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;		
		$scope.formData = {};
        $scope.status = ['Active', 'Inactive'];

        $scope.isNameUnique = true;

        // Function to check if the name is unique
        $scope.checkUniqueType = function(type) {
            // Check if we're in edit mode (i.e., if there's an ID in the state params)
            if ($state.params.id) {              
                console.log($scope.formData.originalName);
                console.log(type);                            
                // In edit mode, compare the new name to other records but exclude the current record's name
                if (type !== $scope.formData.originalName) {  // Compare with the original name
                    $http.post(apiUrl + '/api/check_email_templte_type_unique', { type: type })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            $scope.email_temp_type_form.type.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking type uniqueness:', error);
                        });
                } else {
                    $scope.isNameUnique = true;
                    // If the name is not changed, set it as valid
                    $scope.email_temp_type_form.type.$setValidity("unique", true);
                }
            } else {
                // If it's a new record, proceed with the usual uniqueness check
                if (type) {
                    $http.post(apiUrl + '/api/check_email_templte_type_unique', { type: type })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            $scope.email_temp_type_form.type.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking type uniqueness:', error);
                        });
                }
            }
        };    
        
        $scope.submitEmailTemplateTypeVariables = function (isValid) {         
            $scope.formSubmit = true;    
           // $scope.formData.status = $scope.formData.status.toLowerCase();                  
            if(isValid){ 
                $rootScope.showLoader(true);  
                if($state.params.id){      
                    $http.put(apiUrl+'/api/email-template-type-variables/'+$state.params.id, $scope.formData).success(function(response){						
						$rootScope.showLoader(false);
						if(response.error){
							Notification.error(response.result.message);
						}else{
							Notification.success(response.result.message);
                            $state.go('index.email_template_type_variable_browse');						
						}
					});
                }else{
                    $http.post(apiUrl+'/api/email-template-type-variables',$scope.formData).success(function(response){
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message);                
                        }else{                
                            Notification.success(response.result.message);
                            $state.go('index.email_template_type_variable_browse');                
                        }            
                    });
                }
            }
        };

        //get data for edit screen
		if($state.params.id){            
			$http.get(apiUrl+'/api/email-template-type-variables/'+$state.params.id+'/edit').success(function(response){
				if(response.error){					
					Notification.error(response.result.message);
				}else{
					var resValue = response.result.data;
					$scope.formData.type = resValue.type;
                    $scope.formData.variables = resValue.variables;

                    if (!$scope.formData.originalName) {  // Only set it once
                        $scope.formData.originalName = resValue.type;
                    }             
                    
                    /*if (resValue.status === 'active') {
                        $scope.formData.status = 'Active';
                    } else if (resValue.status === 'inactive') {
                        $scope.formData.status = 'Inactive';
                    }*/                    
				}
			});
		}else{
           // $scope.formData.status = 'Active';
        }       		
    }]);