angular.module('deitz')
    .controller('EmailTemplateController', [
        '$scope', 
        '$rootScope',
        'PermissionFactory' , 
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'RolesFactory',
        'commonFactory',
        '$compile',
        '$uibModal',
        '$filter',
        'apiUrl',
        'Notification',
        '$window',
    function (
        $scope,
        $rootScope,
        PermissionFactory,
        DTOptionsBuilder,
        DTColumnBuilder,
        RolesFactory,
        commonFactory,
        $compile,
        $uibModal,
        $filter,
        apiUrl,
        Notification,
        $window) {


        $scope.dtInstance = {};
        
        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);
        };       
      

        $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withDataProp('data')
        .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {           
            var options = {
                skip: aoData[3].value,
                limit: aoData[4].value,
                order: aoData[2].value,
                search: aoData[5].value                
            };            
           commonFactory.getWithPaginate('/api/email-template', options)
            .success(function(response) {
                if (response) {
                    $rootScope.showLoader(false);
                    fnCallback({
                        recordsTotal: response.recordsTotal,
                        recordsFiltered: response.count,
                        data: response.result
                    });
                }
            }) 
        }) 
        .withOption('processing', true)
        .withLanguage({ 
            "sProcessing": "<img src='img/loading_bar.gif'/>",   
        })
        .withOption('serverSide', true)          
        .withPaginationType('simple_numbers')
        .withOption('searchDelay', 500)
        .withOption('order', [0, 'desc'])
        .withOption('createdRow', $scope.createdRow)  
        .withOption('headerCallback', function (header) {
            // Use this headerCompiled field to only compile header once
            if (!$scope.headerCompiled) {
                $compile(angular.element(header).contents())($scope);
            }
        })

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withTitle('Name').withOption('sortable', true).withOption('searchable', true),
            DTColumnBuilder.newColumn('status').withTitle('Status'),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).renderWith(function(data, type, full, meta){
                return  '<a class="btn btn-circle btn-primary" ui-sref="index.email_template_edit({id:'+full.id+'})" title="Edit Email Template"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;' +
                        '<a class="btn btn-circle btn-danger" ng-click="delete('+full.id+')" title="Delete Email Template"><i class="fa fa-trash"></i></a>&nbsp;&nbsp;' +
                        '<a class="btn btn-circle btn-primary" ng-click="clone_template('+full.id+')" title="Clone Email Template"><i class="fa fa-copy"></i></a>&nbsp;&nbsp;' +
                        '<a class="btn btn-circle btn-primary" ng-click="export_template('+full.id+')" title="Export Email Template"><i class="fa fa-download"></i></a>';
            }).notSortable()
        ];

        $scope.clone_template = function(emailtemplate_id){            
            $rootScope.showLoader(true);            
            var data = {id : emailtemplate_id};        
            commonFactory.post('/api/clone-email-template',data).success(function (res) {
                $rootScope.showLoader(false); 
                if(res.error){
                    Notification.error(res.result.message);    
                }else{
                    Notification.success(res.result.message);                
                }
                $scope.reloadData();
            }).error(function (err) {
                $rootScope.showLoader(false);
                Notification.error(err.result.message);
                $scope.reloadData(); 
            });                    
        }


        $scope.export_template = function(emailtemplate_id){            
            $rootScope.showLoader(true);            
            var data = {id : emailtemplate_id};        
            commonFactory.post('/api/export-email-template',data).success(function (res) {
                $rootScope.showLoader(false);               
                if(res.result.error){
                    Notification.error(res.result.error || "Something went wrong!");
                } else {
                    $window.open(apiUrl +'/api/download-template-file?fileName='+res.result.zip_file);                    
                    Notification.success("Zip file downloaded successfully");
                }
                $scope.reloadData();
            }).error(function (err) {
                $rootScope.showLoader(false);
                Notification.error(err.result.message);
                $scope.reloadData(); 
            });                    
        }

        $scope.delete = function(emailtemplate_id){
            swal({
                    title: "Are you sure ?",
                    text: "you want to delete",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    
                    if (isConfirm) {

                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/email-template/'+emailtemplate_id).success(function (res) {
                            $rootScope.showLoader(false); 
                            if(res.error){

                                Notification.error(res.result.message);    
                            }else{

                                Notification.success(res.result.message);
                            
                            }
                            $scope.reloadData();
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $scope.reloadData(); 
                        });
                    }
                });
        }

        $scope.select_import_email_template = function (content) {       
            $rootScope.showLoader(true);
            var PreviewModal = $uibModal.open({
                templateUrl: "modules/email_template/select_import_template.html",
                controller: 'ImportEmailTemplateController',   
                resolve: {                   
                    previewContent: function () {
                        return content; // Pass the content to the modal
                    }
                },           
                keyboard: false,
                backdrop: false,
                close: function () {
                }
            });

            PreviewModal.opened.then(function(){
                $rootScope.showLoader(false);
            });
        };    
    }]);