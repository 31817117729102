angular.module('deitz').controller('firm_sort2Controller', [
    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$q', '$compile',
    function(
        $scope, $rootScope, $state, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $q, $compile
    ){

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/firm_sort2', options)
                    .success(function(response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function(settings) {
                $scope.inProgress = true;
            }).withOption('fnDrawCallback', function() {
                $scope.inProgress = false;
            });
            
        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Name'),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
            .renderWith(function(data, type, full, meta) {
                if (data && data.id) {
                    var tooltip = (data.is_active == '1') ? "Active" : "Inactive";
                    var newActive = (data.is_active == '1') ? "0" : "1";
                    var btn = (data.is_active == '1') ? 'btn-success' : 'btn-danger';
                    return '<button class="btn btn-primary btn-circle" ng-if="havePermission(\'firm_sort2\',\'edit\')" uib-tooltip="Edit" ui-sref="code_tables.firm_sort2_edit({id : ' + data.id + '})" ><i class="fa fa-pencil"></i></button> &nbsp; <button class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm '+btn+'" ng-click="changeIsActiveStatus(' + data.id + ',\''+ newActive +'\')">' + tooltip + '</button>';
                }

            })
        ];
        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#firm_sort2_list').DataTable().page();
            swal({
                title: "Alert!",
                text: "Are you sure want to "+status+" this Firm sort2?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $rootScope.showLoader(true);
                    var data = {id : id, is_active : newStatus};

                    commonFactory.post('/api/firm_sort2/update-is-active-status', data).success(function(res){
                        $rootScope.showLoader(false); 
                        if(res.error){
                            Notification.error(res.result.message);    
                        }else{
                            Notification.success(res.result.message);
                        }
                        $('#firm_sort2_list').DataTable().page(page).draw(false);
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                        Notification.error(err.result.message);
                        $('#firm_sort2_list').DataTable().page(page).draw(false);
                    });
                }
            });

        };
    }]);
