angular.module('deitz').controller('summaryBillingByFirmController', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory','$timeout','$filter', '$uibModal','$window', 
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory,$timeout,$filter,$uibModal,$window,
    ){

        $scope.report = {
            salesPersonsIds : [],
            startDate : moment(),
            endDate : moment()
        };  
        $scope.selectedFirms = [];
        $scope.selectedJobs = [];
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};
        $scope.title = "Summary Billing By Firm";
        $rootScope.isSalesLogin = localStorage.getItem('isSalesLogin')
        $scope.isSalesPersonLogin = localStorage.getItem('isSalesLogin');
        
        //get business units
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
            $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});

        });        

        $scope.filter = {
            orderByField : 'NAME',  
            reverseSort : false,    
            currentPage : 1,    
            itemsPerPage : 10,  
            totalItems : 0, 
            searchKeyword : '' 
        };

        $scope.show_loader = true;

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting Job list
        $scope.jobList = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
                if($rootScope.isSalesLogin){
                    response.result.resources.forEach(function (e) {
                        if(e.Emai_Address == $rootScope.auth_user.email){
                            $scope.report.salesPersonsIds.push(e.id)
                        }
                    });
                }
            }
            $rootScope.showLoader(false);
        });
        
        /*Getting all groups */
        $http.get(apiUrl + '/api/get-firm-groups').success(function(response) {            
            $scope.firmGroups = response.result;
        });
        
        //Getting all terms 
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.termsList = res.data;
        });

        //Getting all sources from source table.
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.sourcesList=response.data.data;       
        }); 

        //Getting all status of firm.
        $http.get(apiUrl + '/api/get-status-of-firm').success(function(response) { 
            $scope.statusList=response.result;       
        }); 

        $scope.reset = function(){
            $scope.filter = {
                orderByField : 'NAME',  
                reverseSort : false,    
                currentPage : 1,    
                itemsPerPage : 10,  
                totalItems : 0, 
                searchKeyword : '' 
            };

            $scope.report = {
                startDate : moment(),
                endDate : moment()
            };

            $scope.selectedFirms = [];
            $scope.selectedJobs = [];
            $scope.reportData = [];
            $scope.filterdReportData = [];
        }

        $scope.searchData = function(){
            localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
            $scope.report.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);

            $scope.report.isSalesPersonLogin = $rootScope.isSalesLogin;
            // Reset filter params
            $scope.filter = {
                orderByField : 'NAME',  
                reverseSort : false,    
                currentPage : 1,    
                itemsPerPage : 10,  
                totalItems : 0, 
                searchKeyword : ''
            };

            $scope.report.jobNos = $scope.selectedJobs.map(function(a){
                return  a.JOB_NO;
            });
            
            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function(a){
                return  a.id;
            });

            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM');
            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM');

            if($scope.report.endDate < $scope.report.startDate){
                Notification.error('Please enter correct date range. (start date is always less than end date)');
                return;
            }

            $rootScope.showLoader(true);
            commonFactory.post('/api/get-summary-billing-by-firm', $scope.report)
                .success(function(response) {

                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else{
                    
                        $scope.reportData =  response.result.data;
                        $scope.filterdReportData =  response.result.data;
                        $scope.filter.totalItems  = $scope.reportData.length;
                        $scope.pageChange(1);
                        $scope.selectedMonths =  response.result.months;

                        if($scope.reportData.length == 0){
                            Notification.error('No records found!');
                        }
                    }
                })
                .error(function(err)
                {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        $scope.pageChange = function(currpg){
            $scope.show_loader = true;
            $scope.filter.currentPage = currpg;
            if($scope.filter.searchKeyword.length){
                $scope.searchFilter();return;
            }
            $scope.filterdReportData = angular.copy($scope.reportData.slice((($scope.filter.currentPage-1) * $scope.filter.itemsPerPage), (($scope.filter.currentPage)*$scope.filter.itemsPerPage)));
            $rootScope.showLoader(false);
            $timeout(function() {
                $scope.show_loader = false;
            }, 2000);
        }

        $scope.lengthChanged = function(length){
            $scope.show_loader = true;
            $scope.filter.itemsPerPage = length;
            $timeout(function() {
                $scope.pageChange(1)
            }, 100);
        }

        $scope.orderByFilter = function(orderBy) {
            $scope.show_loader = true;
            $scope.filter.reverseSort = (orderBy !== null && $scope.filter.orderByField === orderBy) ? !$scope.filter.reverseSort : false;
            $scope.filter.orderByField = orderBy;
            $timeout(function() {
                $scope.reportData = $filter('orderBy')($scope.reportData, $scope.filter.orderByField, $scope.filter.reverseSort);
                $scope.pageChange(1)
            }, 100);
        };

        $scope.searchFilter = function(){
            $scope.show_loader = true;
            $timeout(function() {
                $scope.filterdReportData = angular.copy($filter('filter')($scope.reportData, $scope.filter.searchKeyword));
                $scope.filter.totalItems = $scope.filterdReportData.length;
                $scope.filterdReportData = $scope.filterdReportData.slice((($scope.filter.currentPage-1) * $scope.filter.itemsPerPage), (($scope.filter.currentPage)*$scope.filter.itemsPerPage));
            }, 100);    
            $timeout(function() {
                $scope.show_loader = false;
            }, 2000);
        }

        $scope.totalSummary = function(totalsummary) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/summary_billing_by_firm/totalSummaryModal.html',
                controller: function($scope, $uibModalInstance) {
                    $scope.totalsummary = totalsummary;
                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            });
        };
        
        $scope.totalSummarybyBU = function(totalsummary, totalMonths) {
            var modalInstance = $uibModal.open({
                templateUrl: 'modules/code_tables/summary_billing_by_firm/totalBUSummaryModal.html',
                controller: function($scope, $uibModalInstance, BusinessUnitsList) {
                    $scope.totalsummary = totalsummary;
                    $scope.BusinessUnitsList = BusinessUnitsList;
        
                    $scope.getBUName = function(buId) {
                        const bu = $scope.BusinessUnitsList.find(item => item.id == buId);
                        return bu ? bu.name : 'Unknown';
                    };
        
                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
        
                    const groupedData = {};
                    const allMonths = new Set();
                    angular.forEach(totalMonths, (month) => {
                        allMonths.add(month.MONTH);
                    });
                    $scope.months = Array.from(allMonths).sort();
                    
                    angular.forEach($scope.totalsummary, (entry) => {
                        const buId = entry.JOB_BU;
                        const month = entry.MONTH;
                        const invAmt = entry.INV_AMT || 0; 
        
                        if (!buId || !month) {
                            console.warn('Invalid entry:', entry);
                            return; 
                        }
        
                        if (!groupedData[buId]) {
                            groupedData[buId] = {
                                BUName: $scope.getBUName(buId),
                                MonthlyTotals: {}
                            };
                        }
        
                        if (!groupedData[buId].MonthlyTotals[month]) {
                            groupedData[buId].MonthlyTotals[month] = 0;
                        }
                        groupedData[buId].MonthlyTotals[month] += invAmt;
                    });
        
                    $scope.totalRow = {
                        BUName: 'Total',
                        MonthlyTotals: {}
                    };
                    $scope.months.forEach(month => {
                        $scope.totalRow.MonthlyTotals[month] = 0;
                    });
        
                    angular.forEach(groupedData, (data) => {
                        $scope.months.forEach(month => {
                            if (data.MonthlyTotals[month]) {
                                $scope.totalRow.MonthlyTotals[month] += data.MonthlyTotals[month];
                            }
                        });
                    });
        
                    $scope.groupedData = groupedData;
                },
                resolve: {
                    BusinessUnitsList: function() {
                        return $scope.BusinessUnitsList;
                    }
                },
                windowClass: 'full-width-model'
            });
        };
        $scope.exportSummary = function(){
            $rootScope.showLoader(true); 
            $scope.report.export = true; 
            console.log($scope.report);
        
            commonFactory.post('/api/get-summary-billing-by-firm', $scope.report, {responseType: 'blob'})
            .success(function(response) {
                $rootScope.showLoader(false);         
                if(response.error){
                    Notification.error(response.result.message || 'Something went wrong!');
                } else {
                    var url= response.result.path;
                    var save = document.createElement('a');
                    save.href = url;
                    save.target = '_blank';
                    save.download = response.result.name;
                    var event = document.createEvent('Event');
                    event.initEvent('click', true, true);
                    save.dispatchEvent(event);
                    save.click()
                    
                }
            })
            .error(function(err) {
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false); // Hide loading indicator in case of error
            });
        };
             
}]);
