angular.module('deitz')
    .controller('addEmailTemplateController',
            ['$scope',
            '$rootScope',           
            'codeFactory',
            '$http',
            '$state',
            'Notification',
            'apiUrl',
            '$uibModal',
            function ($scope,
            $rootScope,              
            codeFactory, 
            $http,
            $state,
            Notification,
            apiUrl,
            $uibModal) {
        $scope.page_title = $state.current.data.pageTitle;
        
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.nameChangedError = false;  		
		$scope.formData = {};
        $scope.variables = [];
        /*$scope.variables = [
            "{{businessunit_name}}","{{businessunit_address_1}}","{{businessunit_address_2}}","{{businessunit_address_3}}","{{businessunit_phone}}","{{job_no}}","{{job_status}}","{{job_date}}","{{job_time}}","{{case_caption}}","{{witness_list}}","{{turnaround}}","{{connection_method}}","{{job_type}}","{{location}}","{{services}}","{{pre_exhibits}}","{{upload_exhibit_link}}","{{job_note}}","{{scheduling_party_firm}}","{{attorney_row}}","{{scheduling_party_scheduled_by}}","{{bill_to}}","{{client_file_number}}","{{claim_number}}","{{claim_adjuster}}","{{scheduling_email_footer}}","{{vc_link}}","{{webex_meeting_number}}","{{webex_url}}","{{webex_password}}","{{webex_phones}}","{{zoom_join_url}}","{{zoom_meeting_id}}","{{zoom_meeting_password}}","{{schedule_type}}","{{logo_header}}"
          ]*/

        /*ckeditor start */
    const {
        ClassicEditor,
        SourceEditing,
        Essentials,
        Bold,
        Italic,
        Font,
        Paragraph,
        Table,
        TableColumnResize,
        TableCaption,
        TableToolbar, 
        Image,
        ImageCaption,
        ImageResize,
        ImageStyle,
        ImageToolbar,
        LinkImage,
        GeneralHtmlSupport,
        Heading,
        FullPage,
        ButtonView,
        SpecialCharacters,
        SpecialCharactersEssentials,
        BlockQuote,
        CodeBlock,
        Highlight,
        HorizontalLine,
        AutoLink,
        Link,
        List, 
        ShowBlocks,
        Alignment,
        TextPartLanguage             
    } = CKEDITOR;  
    $scope.Temp_type_variables_List = [];
        //get all department
        $http.get(apiUrl + '/api/getemail-template-type_variables').success(function(response) {            
            $scope.Temp_type_variables_List=response.result;
        });
        
        $scope.editor = '';
        $scope.formData = {
            email_content: ''
        };
        $scope.status = ['Active', 'Inactive'];           
        $scope.toolbarItems = ['undo', 'redo', '|', 'bold', 'italic', '|', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'SourceEditing', 'insertTable','insertImage', 'heading', 'preview', 'specialCharacters', 'blockQuote', 'codeBlock', 'highlight', 'horizontalLine', 'link', 'bulletedList', 'numberedList','showBlocks', 'alignment', 'textPartLanguage'];
        $scope.initEditor = function(){         
            ClassicEditor
            .create( document.querySelector( '#htmleditor' ), {                            
                toolbar: {
                    items: $scope.toolbarItems
                },  
                plugins: [ Essentials, Bold, Italic, Font, Paragraph, SourceEditing, Table, TableColumnResize, TableCaption, TableToolbar, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar, LinkImage, GeneralHtmlSupport,Heading, FullPage, SpecialCharacters, SpecialCharactersEssentials, BlockQuote, CodeBlock, Highlight, HorizontalLine, AutoLink, Link, List, ShowBlocks, Alignment, TextPartLanguage, TextPartLanguage],
                htmlSupport: {
                    allow: [
                        {
                            name: /.*/,
                            attributes: true,
                            classes: true,
                            styles: true
                        }
                    ]
                },    
                additionalConfig: {
                    // Add custom styles to the editor's content area to disable auto-capitalization
                    styles: {
                        'text-transform': 'none',  // This will remove auto-capitalization
                    }
                },                                 
                autoParagraph: false,
                allowContentInside : false,
                table: {
                    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells', 'toggleTableCaption' ],
                    // Prevent wrapping tables in <figure class="table">
                    tableProperties: {
                        class: '' // Add an empty string to prevent the default class
                    }
                },
                image: {
                    toolbar: ['imageTextAlternative', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight']
                }, 
                extraPlugins: [
                    function (editor) {
                        editor.ui.componentFactory.add('preview', locale => {
                            const button = new ButtonView(locale);
                            button.set({
                                label: 'Preview',  // Text label for the button
                                icon: '<svg class="ck ck-icon ck-reset_all-excluded ck-icon_inherit-color ck-button__icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 6c-3.313 0-6 2.687-6 6s2.687 6 6 6 6-2.687 6-6-2.687-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-10c-.738 0-1.451.263-2.03.723-1.135.894-1.823 2.302-1.823 3.777s.688 2.883 1.823 3.777c.579.46 1.292.723 2.03.723s1.451-.263 2.03-.723c1.135-.894 1.823-2.302 1.823-3.777s-.688-2.883-1.823-3.777c-.579-.46-1.292-.723-2.03-.723zm0 4c-.551 0-1 .449-1 1s.449 1 1 1 1-.449 1-1-.449-1-1-1z"></path></svg>',
                                tooltip: true       // Tooltip still enabled
                            });
                                                       
                
                            // Add a custom class for styling
                            button.extendTemplate({
                                attributes: {
                                    class: 'custom-preview-button'
                                }
                            });
                
                            button.on('execute', () => {                                
                                if ($scope.formData.email_type_id === null || $scope.formData.email_type_id === undefined) {
                                    alert("Email Type is required.");
                                    return;
                                } else {
                                    var selectedEmailTypeId = $scope.formData.email_type_id; // selected option id
                                    var selectedEmailType = $scope.Temp_type_variables_List.find(option => option.id === selectedEmailTypeId)?.type;

                                    const previewContent = $scope.editor.getData();
                                    $scope.showPreview(previewContent,selectedEmailType);
                                }                                                                
                            });
                
                            return button;
                        });
                    }
                ]
                                            
            })
            .then(newEditor => {
                $scope.editor = newEditor;
                $scope.editor.setData($scope.formData.email_content);             
            })
            .catch(error => {
                console.error('There was a problem initializing the editor:', error);
            });
        }
       
        $scope.showPreview = function (content,emailtype) {       
            $rootScope.showLoader(true);
            var PreviewModal = $uibModal.open({
                templateUrl: "modules/email_template/preview.html",
                controller: 'PreviewEmailTemplateController',   
                resolve: {                   
                    previewContent: function () {
                        return content; // Pass the content to the modal
                    },
                    emailType: function () {
                        return emailtype; // Pass email_type_id to the modal
                    }
                },           
                keyboard: false,
                backdrop: false,
                close: function () {
                }
            });

            PreviewModal.opened.then(function(){
                $rootScope.showLoader(false);
            });
        };

        $scope.isNameUnique = true;

        // Function to check if the name is unique
        $scope.checkUniqueName = function(name) {
            // Check if we're in edit mode (i.e., if there's an ID in the state params)
            if ($state.params.id) {
                // In edit mode, compare the new name to other records but exclude the current record's name
                if (name !== $scope.formData.originalName) {  // Compare with the original name
                    $http.post(apiUrl + '/api/check_email_templte_name_unique', { name: name })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            $scope.email_temp_form.name.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking name uniqueness:', error);
                        });
                } else {
                    // If the name is not changed, set it as valid
                    $scope.email_temp_form.name.$setValidity("unique", true);
                }
            } else {
                // If it's a new record, proceed with the usual uniqueness check
                if (name) {
                    $http.post(apiUrl + '/api/check_email_templte_name_unique', { name: name })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            $scope.email_temp_form.name.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking name uniqueness:', error);
                        });
                }
            }
        };
        
        


        /*editor*/
        $scope.cleanUpContent = function(content) {
            // Remove empty <p> tags and their content           
            return content.replace(/<figure[^>]*>|<\/figure>/g, '');
        } 
                

        $scope.updateVariables = function(selectedEmailTypeId) { 
            $http.get(apiUrl + '/api/getemail-template-type_variables').success(function(response) {            
                $scope.Temp_type_variables_List=response.result;                
                var selectedOption = $scope.Temp_type_variables_List.find(function(option) {
                    return option.id === selectedEmailTypeId;
                });               
                // If the selected option exists, update the 'variables' field
                if (selectedOption) {
                    $scope.variables = selectedOption.variables.split(',').map(item => item.trim());     
                } else {
                    // Clear the 'variables' field if no valid selection is made                   
                    $scope.variables = '';
                }
            });            
            // Find the selected option in the list based on the selectedEmailTypeId            
        };       

        $scope.submitEmailTemplate = function (isValid) {  
                 
            $scope.formSubmit = true; 
            $scope.formData.status = $scope.formData.status.toLowerCase();  
            $scope.formData.email_content = $scope.editor.getData();

            var submit_data = $scope.formData;             
            submit_data.email_content = $scope.editor.getData();             
            submit_data.email_content = $scope.cleanUpContent(submit_data.email_content);    
            
            if ($scope.nameChangedError) {
                Notification.error('Form submission prevented due to name change error');
                return;                
              }            
            if (!submit_data.email_content || submit_data.email_content.replace(/<[^>]*>/g, '').replace(/\s*&nbsp;\s*/g, '').trim() === "") {
                // If empty, the form is invalid
                Notification.error('Please Add Description');
                return;   
            }            
            if(isValid){   
                $rootScope.showLoader(true); 
                if($state.params.id){                    
                    $http.put(apiUrl+'/api/email-template/'+$state.params.id, submit_data).success(function(response){						
						$rootScope.showLoader(false);
						if(response.error){
							Notification.error(response.result.message);
						}else{
							Notification.success(response.result.message);
                            $state.go('index.email_template_browse');						
						}
					});
                }else{
                    $http.post(apiUrl+'/api/email-template',submit_data).success(function(response){
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message);                
                        }else{                
                            Notification.success(response.result.message);
                            $state.go('index.email_template_browse');                
                        }            
                    });
                }
            }
        };

        //get data for edit screen
		if($state.params.id){
			$http.get(apiUrl+'/api/email-template/'+$state.params.id+'/edit').success(function(response){
				if(response.error){					
					Notification.error(response.result.message);
				}else{
					var resValue = response.result.data;
					$scope.formData.name = resValue.name;				
                    $scope.formData.dept_id = resValue.dept_id;					
                    $scope.formData.receipts = resValue.receipts;					
                    $scope.formData.temp_category_id = resValue.temp_category_id;					
                    $scope.formData.temp_style = resValue.temp_style;					
                    $scope.formData.trigger = resValue.trigger;					                    
                    $scope.formData.email_content = resValue.email_content;
                    $scope.formData.email_type_id = resValue.email_type_id;                    
                    $scope.updateVariables($scope.formData.email_type_id); 

                    if (resValue.status === 'active') {
                        $scope.formData.status = 'Active';
                    } else if (resValue.status === 'inactive') {
                        $scope.formData.status = 'Inactive';
                    }
                 
                    setTimeout(function(){ $scope.initEditor();}, 500);	                    	
				}
			});


            $scope.setOriginalName = function() {
                if (!$scope.formData.originalName) {  // Only set it once
                  $scope.formData.originalName = $scope.formData.name;
                }
              };
              
              // Function to check if the user is trying to change the name after it has been set
              $scope.checkIfChanged = function(newName) {                
                // Check if the name was set and if it's different from the original name
                if ($scope.formData.originalName && newName !== $scope.formData.originalName) {                   
                    if ($scope.formData.originalName.includes('_copy')) {
                        // Allow the name change if '_copy' is in the original name
                        $scope.nameChangedError = false;
                    } else {
                        // Set error if the name is being changed after submission
                        $scope.nameChangedError = true;
                    }
                   // Set error if the name is being changed after submission
                } else {
                  $scope.nameChangedError = false;  // Reset error if it's a valid change
                }                
              };
    
            $scope.$watch('formData.name', function(newVal, oldVal) {
                if (newVal && !oldVal) {
                  $scope.setOriginalName();  // Set original name when it's first entered
                }
              });

		}else{
            $scope.formData.status = 'Active';
            $scope.initEditor();
        } 
         //get all department
         $http.get(apiUrl + '/api/getemail-template-department').success(function(response) {            
            $scope.DeptList=response.result
        });
        //get all department
        $http.get(apiUrl + '/api/getemail-template-category').success(function(response) {            
            $scope.Temp_Cat_List=response.result
        });      
        
    }]);