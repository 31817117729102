angular.module('deitz').controller('singleInvoicePaymentController', [

    '$scope', '$rootScope', '$http', 'apiUrl', '$state', '$stateParams', '$uibModal', 'cashReceiptFactory', 'commonFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$filter', '$q', 'configFactory', '$timeout','$location',
    function (
        $scope, $rootScope, $http, apiUrl, $state, $stateParams, $uibModal, cashReceiptFactory, commonFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $compile, $filter, $q, configFactory, $timeout, $location
    ) {

        $scope.validations = cashReceiptFactory.validations;
        $scope.messages = cashReceiptFactory.messages;
        $scope.statusOptions = cashReceiptFactory.statusOptions;
        $scope.adjustmentTypes = cashReceiptFactory.adjustmentTypes;
        $rootScope.getPaymentTypes();
        $scope.paymentTypes = $rootScope.paymentTypes;

        $scope.assignedInvNo = false;
        $scope.assignedPaidBy = false;
        $scope.exceeded_AR_pay_amt = false;
        $scope.historicalCashReceipts = [];
        $scope.dtInstance = {};
        $scope.dtInstance_creditHistory = {};

        var INV_NO = $stateParams.inv_no;
        $scope.invoiceId = $stateParams.inv_no;
        $scope.invoiceArray = {};

        $scope.headerCompiled = false;

        // if (INV_NO) {
        //     $http.get(apiUrl + '/api/check/get-current-invoice-check-session/'+INV_NO).then(function(response) { 
        //         if(response.data.session.length==0){
        //             swal({
        //                 title: "Are you sure ?",
        //                 text: "<small>You are running Invoice Operations (PO) and will have exclusive control of PO for a 30 minute session to complete the process.<br> Before accepting control, be sure your reports tie-out to the Batch Totals. <br>  Your User information will be tracked and any OTHER USER attempting to run invoice during your session will see your User information.<br> Once your update is completed your exclusive session will automatically be released.<small>",
        //                 type: "warning",
        //                 showCancelButton: true,
        //                 confirmButtonColor: "#09375f",
        //                 confirmButtonText: "Yes, Do it",
        //                 cancelButtonText: "No!",
        //                 closeOnConfirm: true,
        //                 closeOnCancel: true,
        //                 html:true
        //             },
        //             function (isConfirm) {
        //                 if (isConfirm) {
        //                     $http.post(apiUrl+'/api/check/start-invoice-check-session',{invoice_id:INV_NO})
        //                     .success(function(response){
        //                     if(!response.success){
        //                             swal({
        //                                 title:response.user.name +"  is already using the invoice operation. If you need to contact them, please email "+response.user.email,
        //                                 html:true
        //                             },function(isConfirm){
        //                                 $location.path('/home');
        //                             });
        //                     }
        //                     else{
        //                             Notification.success("Your invoice session start successfully");
        //                             $timeout(function () {
        //                                 Notification.error("Your invoice session is expired");
        //                                 $location.path('/home');
        //                             }, response.session.session_remain_time);
        //                     }
        //                     });
        //                 }
        //                 else{
        //                     $location.path('/home');
        //                 }
        //             });
        //         }
        //         else{
        //             if(response.data.session[0].user_id!=$rootScope.auth_user.id){
        //                 swal({
        //                     title:response.data.session[0].user.name +"  is already using the invoice operation. If you need to contact them, please email "+response.data.session[0].user.email,
        //                     html:true
        //                 });
        //                 $location.path('/home');
        //             }
        //             else{
        //                 $timeout(function () {
        //                     Notification.error("Your invoice session is expired");
        //                     $location.path('/home');
        //                 }, response.data.session[0].session_remain_time);
        //             }
        //         }
        //     });
        //     $scope.closeInvoiceSession = function(){
        //         $http.post(apiUrl+'/api/check/stop-invoice-check-session',{invoice_id:INV_NO})
        //         .success(function(response){
        //             Notification.success("Invoice session end successfully");
        //             $location.path('/home');
        //         });
        //     }
        //     console.log('asdasd');
        //     return false
        // }
        

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        /* Start Cash receipt history table*/

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to

                $scope.getCashCloseDate(function(err, resp){
                    data.INV_NO = $scope.cashReceiptData.INV_NO ? $scope.cashReceiptData.INV_NO : 1; /* Default set to 1 instead of 0 because there is so many entry available with inv no 00 so it was returning many entries */
                    commonFactory.showDataTable('/api/cash-receipts-by-invoice', data).success(function (res) {
                        if (res.error) {
                            $scope.reloadData();
                        }
                        else {
                            callback(res);
                        }
                    }).error(function (err) {
                        if (err.error !== "token_not_provided") {
                            $scope.reloadData();
                        }
                    });
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sProcessing": "<img src='img/loading_bar.gif'/>"
            })
            .withOption('serverSide', true)
            .withOption('searchDelay', 500)
            .withOption('order', [5, 'desc'])
            .withPaginationType('simple_numbers')
            .withDisplayLength(5)
            .withOption('lengthMenu', [
                [5, 10, 25, 50],
                [5, 10, 25, 50]
            ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtColumns = [];
        var deletebtn = '';
        var reversebtn = '';
        $scope.dtColumns.push(
            
            DTColumnBuilder.newColumn('AMT').withTitle('AMT <hr style="margin-top:5px;margin-bottom:5px"> Total Paid Amt').withOption('searchable', true).withOption('width', "15%").renderWith(function (data, type, full, meta) {
                let amt = '';
                if (data && data > 0) {
                    amt = '$' + $filter('number')(data, 2);
                } else if(data && data < 0) {
                    amt = "<strong style='color:#ed5565'>"+ '-$' + $filter('number')(Math.abs(data), 2) +"</strong>";
                } else{
                    amt = '$' + $filter('number')(0, 2);
                }
                let totalPay = full.client_payment_amount;
                let totalPayAmt = '';
                if (totalPay && totalPay > 0) {
                    totalPayAmt = '$' + $filter('number')(totalPay, 2);
                } else if(totalPay && totalPay < 0) {
                    totalPayAmt = "<strong style='color:#ed5565'>"+ '-$' + $filter('number')(Math.abs(totalPay), 2) +"</strong>";
                } else{
                    totalPayAmt = '$' + $filter('number')(0, 2);
                }
                return amt + '' + '<br><hr style="margin:5px 0px">'+totalPayAmt;
            }),
            DTColumnBuilder.newColumn('adjustment').withTitle('ADJUST').withOption('searchable', true).withOption('width', "10%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn('payment_type').withTitle('Pay Type').withOption('searchable', true).withOption('width', "15%").renderWith(function (data, type, full, meta) {
                var str = '';
                reversebtn = '<a tooltip-trigger="focus mouseenter" uib-tooltip="Reverse" tooltip-placement="top" class="btn btn-xs btn-primary ng-scope" ng-if="havePermission(\'cash_receipt\',\'delete\')" ng-click="reverseCashReceipt(' + full.id + ')"><i class="fa fa-undo"></i></a>';
                deletebtn = '<a tooltip-trigger="focus mouseenter" uib-tooltip="Bounce Delete" tooltip-placement="top" class="btn btn-xs btn-warning ng-scope" ng-if="havePermission(\'cash_receipt\',\'delete\')" ng-click="deleteCashReceiptcheck(' + full.id + ')"><i class="fa fa-trash"></i></a>';
                if (full.adjustment > 0) {
                    deletebtn = '';
                    reversebtn = '';
                    if (full.adjustment_type === 'write_off') {
                        str = 'Write off';
                    } else if (full.adjustment_type === 'adjustment') {
                        str =  'Adjustment';
                    } else if (full.adjustment_type === 'other') {
                        str =  'Other';
                    } else if (full.adjustment_type === 'none') {
                        str =  'None';
                    }
                    else {
                        return '-'
                    }
                }else{

                    if (data && data === 'check') {
                        str = 'Check';
                    } else if (data && data === 'american_express') {
                        str =  'American Express';
                    } else if (data && data === 'discover') {
                        str =  'Discover';
                    } else if (data && data === 'wire_transfer') {
                        str =  'Wire Transfer';
                    } else if (data && data === 'credit_card') {
                        str =  'Credit Card';
                    } else if (data && data === 'visa_card') {
                        str =  'Visa Card';
                    } else if (data && data === 'master_card') {
                        str =  'Master Card';
                    } else if (data && data === 'eft') {
                        str =  'EFT';
                    } else if (data && data === 'on_account_credit') {
                        str =  'On Account Credit';
                        deletebtn = '';
                        reversebtn = '';
                    } else if (data && data === 'on_credit_memo') {
                        str =  'CR-INV Application';
                        deletebtn = '';
                        reversebtn = '';
                    } else if (data && data === 'on_credit_payment') {
                        str =  'CR-Payment';
                        deletebtn = '';
                        reversebtn = '';
                    } else if (data && data === 'inter_company') {
                        str =  'Inter company';
                    } else if (data && data === 'other') {
                        str =  'Other';
                    } else if (data && data === 'refund') {
                        deletebtn = '';
                        reversebtn = '';
                        str =  "<strong style='color:#ed5565'>Refund </strong>";
                    } else if(data && data === 'check_upload'){ 
                        str =  "Check Upload";
                    } else if(data && data === 'bounce'){ 
                        str =  "Bounce";
                        deletebtn = ''; 
                        reversebtn = '';                      
                    } else if(data && data === 'reverse'){ 
                        str =  "Reverse";
                        deletebtn = ''; 
                        reversebtn = '';                      
                    } else if (data && data === 'sync_data') {
                        str =  'Sync Data';
                        deletebtn = '';
                        reversebtn = '';   
                    } else {
                        deletebtn = '';
                        reversebtn = '';   
                        return '-'
                    }
                    if(full.bounce_record){
                        deletebtn = '';
                        reversebtn = '';                      
                    }
                    if(full.reverse_record){
                        reversebtn = '';
                        deletebtn = '';                     
                    }

                    if(full.check_number){
                        str += " [<strong> CHK/REF:"+ full.check_number+ "<strong>] "; 
                    }
                }
                return str;
            }),
            DTColumnBuilder.newColumn(null).withTitle('Paid by').withOption('searchable', true).withOption('width', "15%").notSortable().renderWith(function (data, type, full, meta) {
                if (full && data.insurance_company) {
                    return "<strong  style='color:#1288eb'>"+ data.insurance_company.CompanyName+ "</strong>";
                } else if(full && data.attorney_firm){
                    return "<strong>"+ data.attorney_firm.NAME +"</strong>";
                }
                else if(full && data.is_random_payor==1){
                    return "<strong class='text-warning'>"+ data.payor_name +"</strong>";
                }
                else {
                    return '-'
                }

            }),
            DTColumnBuilder.newColumn('update_by.name').withOption('defaultContent','').withTitle('Applied By').withOption('searchable', false).notSortable().withOption('width', "20%").renderWith(function (data, type, full, meta) {
                if (data) {
                    str = data;
                    if(full.is_random_applied_by=='1'){
                        str = ' <strong>'+ full.random_applied_by + '<strong/> ';
                    }
                    else if(full.auth_user && full.auth_user.first){
                        str += ' (<strong>'+ full.auth_user.first +' '+ full.auth_user.last + '<strong/>) ';
                    }else if(full.auth_user && full.auth_user.depo_attorney){
                        str += ' (<strong>'+ full.auth_user.depo_attorney.name + '<strong/>) ';
                    }

                    if(full.Notes){
                        str += '- <strong style="color:#5f9ea0;">'+ full.Notes + '<strong/>';
                    }

                    if(full.reason){
                        str += '- <strong style="color:#5f9ea0;">'+ full.reason + '<strong/>';
                    }
                    return str;
                }
                else {
                    return '-'
                }

            }),
            DTColumnBuilder.newColumn('netSuitePaymentId').withTitle('OD ID <hr style="margin-top:5px;margin-bottom:5px"> NS ID').withOption('searchable', true).withOption('width', "10%").renderWith(function (data, type, full, meta) {
                let odId = full.id;
                if (data) {
                    return odId + '' + '<br><hr style="margin:5px 0px">' + data;
                } else {
                    return odId + '' + '<br><hr style="margin:5px 0px">' + '-';
                }
            }),
            DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Dt').withOption('searchable', true).withOption('width', "15%").renderWith(function (data, type, full, meta) {
                if (data && $filter('formatDate')(data) != 'Invalid Date') {
                    return $filter('formatDate')(data);
                }
                else {
                    return '-'
                }

            }),
            DTColumnBuilder.newColumn('CreatedDate').withTitle('Applied Date').withOption('searchable', true).withOption('width', "15%").renderWith(function (data, type, full, meta) {
                if (data && $filter('formatDate')(data) != 'Invalid Date') {
                    return $filter('formatDate')(data);
                }
                else {
                    return '-'
                }
            }),
            DTColumnBuilder.newColumn(null).withTitle('').withOption('searchable', false).withOption('width', "5%").notSortable().renderWith(function (data, type, full, meta) {
                var row='';
                if(!full.netSuitePaymentId)
                {
                    if(moment($scope.configData.cash_close_date, 'MM/DD/YYYY').format() < moment(full.PAID_DT).format()) {

                        //Edit button
                        // row = '<a tooltip-trigger="focus mouseenter" uib-tooltip="Edit" tooltip-placement="top" class="btn btn-xs btn-primary" ng-click="showCashReceiptFormForEdit(' + full.id + ')"><i class="fa fa-pencil"></i></a> &nbsp;';
                        //Delete button
                        // row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Delete" tooltip-placement="top" class="btn btn-xs btn-danger ng-scope" ng-if="havePermission(\'cash_receipt\',\'delete\')" ng-click="deleteCashReceipt(' + full.id + ')"><i class="fa fa-trash"></i></a>';
                        row += reversebtn;
                        row += deletebtn;
                    }
                }
                return row;
            })
        );


        /* END CASH RECEIPT HISTORY TABLE*/

        /* START: ACCOUNT CREDIT HISTORY TABLE*/

        $scope.reloadData_creditHistory = function () {
            $scope.dtInstance_creditHistory.rerender();
        };

        $scope.dtOptions_creditHistory = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to
                data.inv_no = $scope.invoiceData ? $scope.invoiceData.INV_NO : 1;

                commonFactory.showDataTable('/api/account_credit_history', data).success(function (res) {
                    if (res.error) {
                        $scope.reloadData_creditHistory();
                    }
                    else {
                        callback(res);
                    }
                }).error(function (err) {
                    if (err.error !== "token_not_provided") {
                        $scope.reloadData_creditHistory();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sProcessing": "<img src='img/loading_bar.gif'/>"
            })
            .withOption('serverSide', true)
            .withOption('searchDelay', 500)
            .withOption('order', [3, 'asc'])
            .withPaginationType('simple_numbers')
            .withDisplayLength(5)
            .withOption('lengthMenu', [
                [5, 10, 25, 50],
                [5, 10, 25, 50]
            ])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtColumns_creditHistory = [];

        $scope.dtColumns_creditHistory.push(
            DTColumnBuilder.newColumn('amt').withTitle('AMT').withOption('searchable', true).withOption('width', "15%").renderWith(function (data, type, full, meta) {
                var row = '';
                if (data && data > 0) {
                    row = "<strong style='color:#4caf50'>"+ '$' + $filter('number')(data, 2) +"</strong>";
                } else if(data && data < 0) {
                    row = "<strong style='color:#ed5565'>"+ '-$' + $filter('number')(Math.abs(data), 2) +"</strong>";
                } else{
                    row = '$' + $filter('number')(0, 2);
                }
                return row;
                
            }),
            DTColumnBuilder.newColumn(null).withTitle('Attorney/ Insurance Co.').withOption('searchable', false).notSortable().withOption('width', "30%").renderWith(function (data, type, full, meta) {
                if (data.attorney_id) {
                    return "<strong>"+ data.attorney.NAME +"</strong>";
                } else if(data.insurance_id){
                    return "<strong  style='color:#1288eb'>"+ data.insurance_company.CompanyName+ "</strong>";
                }
                else if(data.is_random_payor==1){
                    return "<strong class='text-warning'>"+ data.payor_name +"</strong>";
                }
                else {
                    return '-'
                }
            }),
            DTColumnBuilder.newColumn('transaction_type').withTitle('Transaction Type').withOption('searchable', true).withOption('width', "30%").renderWith(function (data, type, full, meta) {
                var str = '';
                if (data === 'over_paid') {
                    str = 'Over Paid ';
                } else if (data === 'credit_used') {
                    str = "On account $" + $filter('number')(Math.abs(full.amt), 2) + " from <a ui-sref='cash_receipt.singleInvoicePayment({inv_no : "+ full.inv_no_used +"})'> <strong ng-if="+ full.org_inv_no_used +">" + full.org_inv_no_used + "</strong><strong ng-if=!"+ full.org_inv_no_used +">" + full.inv_no_used + "</strong></a> used to pay <a ui-sref='cash_receipt.singleInvoicePayment({inv_no : "+ full.original_invoice_no+ "})'><strong>" + full.original_invoice_no + "</strong></a>";
                } else if (data === 'credit_memo_used') {
                    str = "$" + $filter('number')(Math.abs(full.amt), 2) + " Applied from credit memo invoice # <a ui-sref='cash_receipt.singleInvoicePayment({inv_no : "+ full.inv_no_used +"})'> <strong ng-if="+ full.inv_no_used +">" + full.inv_no_used +"</strong></a>";
                } else if (data === 'credit_payment_used') {
                    str = "$" + $filter('number')(Math.abs(full.amt), 2) + " Applied from credit payment #  <strong ng-if="+ full.inv_no_used +">" + full.inv_no_used +"</strong>";
                } else if (data === 'batch_credit_used') {
                    str = "On account $" + $filter('number')(Math.abs(full.amt), 2) + " from Batch payment used to pay <a ui-sref='cash_receipt.singleInvoicePayment({inv_no : "+ full.original_invoice_no+ "})'><strong>" + full.original_invoice_no + "</strong></a>";
                } else if (data === 'refund') {
                    str = "<strong style='color:#ed5565'>Refund </strong>";
                }
                else {
                    return '-'
                }

                if(full.check_number){
                    str += " [<strong> CHK/REF: "+ full.check_number+ "<strong>]";
                }
                return str;
            }),
            DTColumnBuilder.newColumn('created_at').withTitle('Transaction Date').withOption('searchable', true).withOption('width', "15%").renderWith(function (data, type, full, meta) {
                return  moment(data).format('MM/DD/YYYY');
            }),
            DTColumnBuilder.newColumn(null).withTitle('').withOption('searchable', false).withOption('width', "5%").notSortable().renderWith(function (data, type, full, meta) {
                var row = '';
                if (full.transaction_type === 'refund') {
                    row += ' <a tooltip-trigger="focus mouseenter" uib-tooltip="Delete" tooltip-placement="top" class="btn btn-xs btn-danger pull-right" ng-if="havePermission(\'cash_receipt\',\'delete\')" ng-click="deleteAccountCredit(' + full.id + ')"><i class="fa fa-trash"></i></a>';
                }
                return row;
            })
        );

        /* END : ACCOUNT CREDIT HISTORY TABLE*/
        $scope.cashReceiptData = {
            INV_AMT: 0,
            PAID_AMT: 0,
            adjustment : 0,
            ar_paid_amt : 0,
            new_adj_amt: 0,
            over_under_amt : 0,
            RECD_TYP : "M",
            STATUS : 'P',
            payment_operation : 'payment',
            transaction_type : 'other',
            paidBy: "",
            APP_DT : moment().format('MM/DD/YYYY')
        };

        // Getting a invoice details
        $scope.getInvoiceDetail = function(INV_NO, cb){
            $http.get(apiUrl + '/api/CashReceipt/'+INV_NO+'/edit', {}).then(function(response) {
                if(response && !response.data.error){
                    $scope.cashReceiptData = response.data.result.invoiceData;

                    $scope.cashReceiptData.APP_DT = moment().format('MM/DD/YYYY');
                    $scope.cashReceiptData.new_adj_amt = 0;
                    $scope.cashReceiptData.check_amt = 0;
                    $scope.cashReceiptData.ar_paid_amt = 0;
                    $scope.cashReceiptData.adj_typ = "none";
                    $scope.cashReceiptData.PAY_TYPE = "check";
                    $scope.cashReceiptData.RECD_TYP = 'M';
                    $scope.cashReceiptData.payment_operation = 'payment';
                    $scope.cashReceiptData.pullPaidBy = true; 
                    $scope.cashReceiptData.transaction_type = 'other';
                    $scope.invoiceData = $scope.cashReceiptData;

                    $scope.assignedInvNo = true;

                    cb(null, 'one');
                } else{
                    cb(true, null);
                }
            });
        };

        $scope.getstatus = function(status){
            if(status == 'C'){
                return 'Paid';
            }else{
                return 'Pending';
            }
        };


        // Getting attorney firm/insurance co. details
        $scope.pullBillingfirm = function(cb){
            if($scope.cashReceiptData.pullPaidBy){
                var att_id = $scope.cashReceiptData.attorney_id;
                commonFactory.get('/api/attorneys/'+att_id)
                    .success(function (response) {
                        if(! response.error){
                            $scope.cashReceiptData.paidBy = response.result.data || '';
                            $scope.cashReceiptData.paidBy.resType = 1;
                            $scope.assignedPaidBy = true;
                            $scope.getCreditMemos();
                            $scope.getCreditPayments();
                            $scope.getAccountCredits(function(){
                                cb(null, 'two');
                            });
                            return;
                        }
                        cb(null, 'two');

                    })
                    .error(function (err) {
                        cb(null, 'two');
                    });
            } else{
                cb(null, 'two');
            }
        };

        // Getting close date of cash
        $scope.getCashCloseDate = function(cb){

            var data = ['cash_close_date'];

            configFactory.getConfiguration({ keys: data })
                .success(function (response) {
                    $scope.configData = response.configurations;
                    cb(null, 'four');
                })
                .error(function (err) {
                    cb(null, 'err_four');
                });
        };

        // Getting history of invoice payment (Cash receipts)
        $scope.getAccountCredits = function(cb){
            $scope.onAccountAmounts = [];

            commonFactory.post('/api/unused_credit_list', {
                'source_id' : $scope.cashReceiptData.paidBy.id,
                'resType' : $scope.cashReceiptData.paidBy.resType
            })
                .success(function (response) {
                    $scope.historicalCashReceipts = [];
                    if(!response.error){
                        $scope.onAccountAmounts = response.result.credits;
                        angular.forEach($scope.onAccountAmounts, function(value, key){
                            var dropDownString = '';
                            if(value.transaction_type === 'over_paid'){
                                dropDownString = '$'+ value.available_amt + ' FROM INVOICE ' + value.original_invoice_no;
                            } else if(value.transaction_type === 'batch_over_paid'){
                                dropDownString = '$'+ value.available_amt + ' FROM BATCH PAYMENT ON ' + moment(value.created_at).format('MM/DD/YYYY');
                            }

                            if(value.check_number){
                                dropDownString += " ["+ value.check_number +"] "; 
                            }
                            $scope.onAccountAmounts[key]['string'] = dropDownString;
                        });
                    }
                    cb(null, 'three');
                })
                .error(function (err) {
                    cb(null, 'three');
                });
        };

        // Getting Credit Memos (Cash receipts)
        $scope.getCreditMemos = function(cb){
            $scope.onAccountCreditMemos = [];

            commonFactory.post('/api/unused_credit_memos', {
                'source_id' : $scope.cashReceiptData.paidBy.id,
                'resType' : $scope.cashReceiptData.paidBy.resType
            })
            .success(function (response) {
                $scope.historicalCashReceipts = [];
                if(!response.error){
                    $scope.onAccountCreditMemos = response.result.credit_memos;
                    angular.forEach($scope.onAccountCreditMemos, function(value, key) {
                        $scope.onAccountCreditMemos[key]['string'] = '$'+ Math.abs(value.INV_BAL_DUE).toFixed(2) + ' FROM INVOICE ' + value.INV_NO;
                    });
                }
            })
            .error(function (err) {
                console.log(err);
            });
        };

        // Getting Credit Memos (Cash receipts)
        $scope.getCreditPayments = function(cb){
            $scope.onAccountCreditPayments = [];

            commonFactory.post('/api/unused_credit_payments', {
                'source_id' : $scope.cashReceiptData.paidBy.id,
                'resType' : $scope.cashReceiptData.paidBy.resType
            })
            .success(function (response) {
                $scope.historicalCashReceipts = [];
                if(!response.error){
                    $scope.onAccountCreditPayments = response.result.credit_payments;
                    angular.forEach($scope.onAccountCreditPayments, function(value, key) {
                        let payId = value.cashRec_id;
                        let payDate = moment(value.created_at).format('MM/DD/YYYY');
                        if(value.master_payment)
                        {
                            payId = value.master_payment.cashRec_id
                            payDate = moment(value.master_payment.created_at).format('MM/DD/YYYY');
                        }
                        $scope.onAccountCreditPayments[key]['string'] = '$'+ Math.abs(value.available_amt).toFixed(2) + ' FROM PAYMENT #' + payId + ' RECEIVED ON ' + payDate;
                    });
                }
            })
            .error(function (err) {
                console.log(err);
            });
        };

        $scope.fetchNewData = function(INV_NO){
            $rootScope.showLoader(true);
            async.series([
                function(callback){
                    $scope.getInvoiceDetail(INV_NO, function(err, resp){
                        callback(err, resp);
                    });
                },
                function(callback){
                    $scope.pullBillingfirm(function(err, resp){
                        callback(err, resp);
                    });

                }
                // ,
                // function(callback){
                //     $scope.getCashCloseDate(function(err, resp){
                //         callback(err, resp);
                //     });
                // }
            ],
            // optional callback
            function(err, results){
                if(!err){
                    // $scope.fullPayment();
                    $scope.reloadData();
                    $scope.reloadData_creditHistory();
                    $scope.calculateAmount();
                }
                $rootScope.showLoader(false);
            });
        };

        if(INV_NO){
            $scope.fetchNewData(INV_NO);
        }

        $scope.calculateAmount = function(){
            $scope.exceeded_AR_pay_amt = false;
            $scope.exceeded_on_account_amt = false;
            $scope.exceeded_available_amt = false;

            if(isNaN($scope.cashReceiptData.check_amt)){
                $scope.cashReceiptData.check_amt = 0;
            }
            if(isNaN($scope.cashReceiptData.ar_paid_amt) || !$scope.cashReceiptData.ar_paid_amt){
                $scope.cashReceiptData.ar_paid_amt = 0;
            } else if(parseFloat($scope.cashReceiptData.ar_paid_amt) > $scope.cashReceiptData.INV_BAL_DUE){
                // $scope.cashReceiptData.ar_paid_amt = $scope.cashReceiptData.INV_BAL_DUE;
                $scope.exceeded_AR_pay_amt = true;
            }

            if(isNaN($scope.cashReceiptData.new_adj_amt) || !$scope.cashReceiptData.new_adj_amt){
                $scope.cashReceiptData.new_adj_amt = 0;
            } else if(parseFloat($scope.cashReceiptData.new_adj_amt) > $scope.cashReceiptData.INV_BAL_DUE){
                // $scope.cashReceiptData.new_adj_amt = $scope.cashReceiptData.INV_BAL_DUE;
                $scope.exceeded_AR_pay_amt = true;
            }

            if($scope.cashReceiptData.transaction_type === 'on_account'){
                if(parseFloat($scope.cashReceiptData.used_on_account_amount) > $scope.cashReceiptData.INV_BAL_DUE) {
                    $scope.exceeded_on_account_amt = true;
                    $scope.exceeded_available_amt = false;
                } else if($scope.cashReceiptData.reference_inv_no && parseFloat($scope.cashReceiptData.used_on_account_amount) > $scope.cashReceiptData.reference_inv_no.available_amt){
                    $scope.exceeded_on_account_amt = false;
                    $scope.exceeded_available_amt = true;
                }
            }

            if($scope.cashReceiptData.transaction_type === 'credit_memo'){
                if(parseFloat($scope.cashReceiptData.used_credit_memo_amount) > $scope.cashReceiptData.INV_BAL_DUE) {
                    $scope.exceeded_on_account_amt = true;
                    $scope.exceeded_available_amt = false;
                } else if($scope.cashReceiptData.reference_inv_no && parseFloat($scope.cashReceiptData.used_credit_memo_amount) > Math.abs($scope.cashReceiptData.reference_inv_no.INV_BAL_DUE)){
                    $scope.exceeded_on_account_amt = false;
                    $scope.exceeded_available_amt = true;
                }
            }

            if($scope.cashReceiptData.transaction_type === 'credit_payment'){
                if(parseFloat($scope.cashReceiptData.used_credit_payment_amount) > $scope.cashReceiptData.INV_BAL_DUE) {
                    $scope.exceeded_on_account_amt = true;
                    $scope.exceeded_available_amt = false;
                } else if($scope.cashReceiptData.reference_inv_no && parseFloat($scope.cashReceiptData.used_credit_payment_amount) > Math.abs($scope.cashReceiptData.reference_inv_no.INV_BAL_DUE)){
                    $scope.exceeded_on_account_amt = false;
                    $scope.exceeded_available_amt = true;
                }
            }

            $scope.cashReceiptData.over_under_amt = parseFloat($scope.cashReceiptData.check_amt || 0) - parseFloat($scope.cashReceiptData.ar_paid_amt);
        };

        $scope.fullPayment = function(){            
            $scope.cashReceiptData.ar_paid_amt = parseFloat(parseFloat(parseFloat($scope.cashReceiptData.INV_AMT) - parseFloat($scope.cashReceiptData.PAID_AMT) - parseFloat($scope.cashReceiptData.adjustment) - parseFloat($scope.cashReceiptData.new_adj_amt)).toFixed(2)) ;
            $scope.cashReceiptData.check_amt = parseFloat($scope.cashReceiptData.ar_paid_amt);
            $scope.cashReceiptData.STATUS = 'C';
            $scope.calculateAmount();
        };

        $scope.resetForm = function(){
            $scope.cashReceiptData.ar_paid_amt = 0;
            $scope.cashReceiptData.check_amt = 0;
            $scope.cashReceiptData.new_adj_amt = 0;
            $scope.cashReceiptData.STATUS = 'P';
            $scope.cashReceiptData.NOTES = '';
            $scope.cashReceiptData.checkNumber = '';
            $scope.cashReceiptData.reason = '';
            $scope.cashReceiptData.pullPaidBy = false;
            $scope.cashReceiptData.paidBy = '';

            $scope.assignedPaidBy = false;
            $scope.exceeded_AR_pay_amt = false;
            $scope.is_zero_check_amt = false;
            $scope.onAccountAmounts = [];
            $scope.onAccountCreditMemos = [];
            $scope.onAccountCreditPayments = [];
            $scope.cashReceiptData.transaction_type = 'other';
            $scope.calculateAmount();
        };

        $scope.selectedTypeAdjustment = function(){
            $scope.resetForm();
            $scope.cashReceiptData.pullPaidBy= true;
            $scope.pullBillingFirmToPaidBy();
        };

        $scope.searchInvoiceById = function(val){
            return $http.post(apiUrl + '/api/invoice/search-invoice-number', {
                    search: val
            }).then(function(response) {
                return response.data.invoices.map(function(item) {
                    return item;
                });
            });
        };

        //Getting Attorneys list
        $scope.attorneysByName = function(val) {
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val,
                    from_cash_receipt :true
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.pullBillingFirmToPaidBy = function(){
            $rootScope.showLoader(true);
            $scope.unSelectPaidBy();
            $scope.pullBillingfirm(function(){
                $rootScope.showLoader(false);
            });

        };

        $scope.onSelectInvoice = function(item, model, label){
            $state.go('cash_receipt.singleInvoicePayment', {inv_no: item.INV_NO}, {notify: true});
            $stateParams.inv_no = item.INV_NO;
            INV_NO = item.INV_NO;
            $scope.fetchNewData(item.INV_NO);
        };

        $scope.onSelectPaidBy = function(item, model, label){
            $scope.cashReceiptData.paidBy = item;
            $scope.getAccountCredits(function(){
                $rootScope.showLoader(false);
            });
            $scope.getCreditMemos(function(){
                $rootScope.showLoader(false);
            });
            $scope.getCreditPayments(function(){
                $rootScope.showLoader(false);
            });

            $scope.assignedPaidBy = true;
            if(item.id == $scope.cashReceiptData.attorney_firms.id){
                $scope.cashReceiptData.pullPaidBy = true;
            } else{
                $scope.cashReceiptData.pullPaidBy = false;
            }
            $scope.calculateAmount();
        };

        $scope.unSelectInvNo = function(){
            // if($scope.assignedInvNo){
            //     $scope.cashReceiptData = {
            //         INV_AMT: 0,
            //         PAID_AMT: 0,
            //         adjustment : 0,
            //         ar_paid_amt : 0,
            //         new_adj_amt: 0,
            //         over_under_amt : 0,
            //         adj_typ : "none",
            //         // transaction_type: 'other',
            //         PAY_TYPE : "other"
            //     };
            //     $scope.invoiceData = '';
                $scope.assignedInvNo = false;
            //     $scope.assignedPaidBy = false;
            //     $scope.cashReceiptData.paidBy = '';
            //     $scope.historicalCashReceipts = [];
            //     $scope.reloadData();
            //     $scope.reloadData_creditHistory();
            // }
        };
        $scope.clearInvNo = function(){
            if(!$scope.assignedInvNo){
                $scope.cashReceiptData = {
                    INV_AMT: 0,
                    PAID_AMT: 0,
                    adjustment : 0,
                    ar_paid_amt : 0,
                    new_adj_amt: 0,
                    over_under_amt : 0,
                    adj_typ : "none",
                    transaction_type: 'other',
                    PAY_TYPE : "other"
                };
                $scope.invoiceData = '';
                $scope.assignedInvNo = false;
                $scope.assignedPaidBy = false;
                $scope.cashReceiptData.paidBy = '';
                $scope.historicalCashReceipts = [];
                $scope.reloadData();
                $scope.reloadData_creditHistory();
            }  
        }
        $scope.unSelectPaidBy = function(){
            if($scope.assignedPaidBy){
                $scope.cashReceiptData.paidBy = '';
                $scope.assignedPaidBy = false;
                $scope.onAccountAmounts = [];
                $scope.onAccountCreditMemos = [];
                $scope.onAccountCreditPayments = [];
            }
            $scope.calculateAmount();
        };

        $scope.change_adjustment_type = function(){
            if($scope.cashReceiptData.adj_typ === 'none'){
                $scope.cashReceiptData.new_adj_amt = 0;
                $scope.cashReceiptData.reason = '';

                $scope.calculateAmount();
            }
        };

        $scope.makeAmountZero = function(){
            $scope.cashReceiptData.ar_paid_amt = 0;
            $scope.cashReceiptData.check_amt = 0;
            $scope.cashReceiptData.new_adj_amt = 0;
            $scope.exceeded_AR_pay_amt = false;
            $scope.cashReceiptData.reference_inv_no = '';
            //make check number, validation message of check number or ref number blank and also set validation false.
            $scope.cashReceiptData.checkNumber = '';  
            $scope.is_check_ref_no_validate = false;
            $scope.ref_no_validate_message = '';
            $scope.check_no_validate_message = '';
            $scope.calculateAmount();
        };

        $scope.change_payment_type = function(){
            $scope.makeAmountZero();            
        };

        $scope.changedTransactionType = function(){
            $scope.makeAmountZero();
        };

        $scope.changedReferenceInvoice = function(){
            $scope.cashReceiptData.used_on_account_amount = 0;
            if($scope.cashReceiptData.reference_inv_no){
                if($scope.cashReceiptData.reference_inv_no.available_amt <= $scope.cashReceiptData.INV_BAL_DUE){
                    $scope.cashReceiptData.used_on_account_amount = $scope.cashReceiptData.reference_inv_no.available_amt;
                } else{
                    $scope.cashReceiptData.used_on_account_amount = $scope.cashReceiptData.INV_BAL_DUE;
                }
            }
        };

        $scope.changedRefundAmt =  function(){
            if(isNaN($scope.cashReceiptData.ar_paid_amt) || !$scope.cashReceiptData.ar_paid_amt){
                $scope.cashReceiptData.ar_paid_amt = 0;
            } else if(parseFloat($scope.cashReceiptData.ar_paid_amt) > 0 && $scope.cashReceiptData.PAY_TYPE == 'refund'){
                $scope.cashReceiptData.ar_paid_amt = 0 - $scope.cashReceiptData.ar_paid_amt;
            }

            $scope.calculateAmount();
        }

        $scope.paySingleCashReceipt = function(isValid, saveOnly, saveNext){
            if(isValid && $scope.assignedInvNo && $scope.assignedPaidBy && ($scope.cashReceiptData.transaction_type || $scope.cashReceiptData.adj_typ)){

                if(!parseFloat($scope.cashReceiptData.new_adj_amt) > 0 && !parseFloat($scope.cashReceiptData.ar_paid_amt) > 0 && $scope.cashReceiptData.adj_typ !== "open_close_invoice" && $scope.cashReceiptData.transaction_type === "other"){
                    Notification.error('Payment amount can not be zero.');
                    return;
                }

                if($scope.cashReceiptData.transaction_type === "on_account" && !$scope.cashReceiptData.used_on_account_amount > 0){
                    return;
                }

                if($scope.cashReceiptData.payment_operation == 'payment' && $scope.cashReceiptData.transaction_type === "other" && parseFloat($scope.cashReceiptData.check_amt) <= 0){
                    $scope.is_zero_check_amt = true;
                    $timeout(function() {
                        $scope.is_zero_check_amt = false;
                    }, 3000);
                    return false;
                }

                swal({
                        title: "Are you sure ?",
                        text: "You are about to pay an invoice.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $scope.cashReceiptData.ATTORNEY= $scope.cashReceiptData.paidBy;               

                        $rootScope.showLoader(true);

                        var params = {
                            invoiceID: $scope.cashReceiptData.invoiceID,
                            ATTORNEY: {
                                id: $scope.cashReceiptData.attorney_firms.id,
                                NAME_KEY: $scope.cashReceiptData.attorney_firms.NAME_KEY,
                                credit_amount: $scope.cashReceiptData.attorney_firms.credit_amount

                            },
                            paidBy: {
                                resType: $scope.cashReceiptData.paidBy.resType,
                                id: $scope.cashReceiptData.paidBy.id
                            },
                            PAID_DT: moment($scope.cashReceiptData.APP_DT).format('MM/DD/YYYY'),
                            payment_operation : $scope.cashReceiptData.payment_operation,
                            STATUS: $scope.cashReceiptData.STATUS,
                            Notes : $scope.cashReceiptData.NOTES,
                            RECD_TYP : $scope.cashReceiptData.RECD_TYP
                        };

                        if($scope.cashReceiptData.payment_operation === 'payment' && $scope.cashReceiptData.transaction_type === 'other'){
                            params.ar_paid_amt = $scope.cashReceiptData.ar_paid_amt;
                            params.check_amt = $scope.cashReceiptData.check_amt;
                            params.checkNumber = $scope.cashReceiptData.checkNumber;
                            params.PAY_TYPE = $scope.cashReceiptData.PAY_TYPE;
                            params.reference_number = $scope.cashReceiptData.reference_number;
                            params.over_under_amt = $scope.cashReceiptData.over_under_amt;
                        }
                        else if($scope.cashReceiptData.payment_operation === 'payment' && $scope.cashReceiptData.transaction_type === 'on_account'){
                            params.PAY_TYPE = 'on_account_credit';
                            params.reference_inv_no = $scope.cashReceiptData.reference_inv_no;
                            params.used_on_account_amount = $scope.cashReceiptData.used_on_account_amount;


                        }
                        else if($scope.cashReceiptData.payment_operation === 'payment' && $scope.cashReceiptData.transaction_type === 'credit_memo'){
                            params.PAY_TYPE = 'on_credit_memo';
                            params.reference_inv_no = $scope.cashReceiptData.reference_inv_no;
                            params.used_credit_memo_amount = $scope.cashReceiptData.used_credit_memo_amount;


                        } else if($scope.cashReceiptData.payment_operation === 'payment' && $scope.cashReceiptData.transaction_type === 'credit_payment'){
                            params.PAY_TYPE = 'on_credit_payment';
                            params.reference_inv_no = $scope.cashReceiptData.reference_inv_no;
                            params.used_credit_payment_amount = $scope.cashReceiptData.used_credit_payment_amount;


                        } else if($scope.cashReceiptData.payment_operation === 'adjustment'){
                            params.new_adj_amt = $scope.cashReceiptData.new_adj_amt;
                            params.adj_typ = $scope.cashReceiptData.adj_typ;
                            params.reason = $scope.cashReceiptData.reason;
                            params.PAY_TYPE = 'other';
                        }

                        params.overOrUnder = 'equal';
                        if($scope.cashReceiptData.over_under_amt < 0){
                            params.overOrUnder = 'under';
                        } else if($scope.cashReceiptData.over_under_amt > 0){
                            params.overOrUnder = 'over';
                        }
                        
                        commonFactory.post('/api/singleInvoicePayment', params)
                            .success(function (response) {
                                $rootScope.showLoader(false);
                                if(response.error){
                                    // swal(response.result.message || 'Error while paying the invoice.', '', "error");
                                    Notification.error(response.result.message || 'Entry has been successfully removed.');
                                } else{
                                    Notification.success(response.result.message || 'Payment has been done successfully.');
                                    $scope.onAccountAmounts = [];
                                    $scope.onAccountCreditMemos = [];
                                    $scope.onAccountCreditPayments = [];
                                    if(saveOnly){
                                        $scope.fetchNewData(INV_NO);
                                    } else if(saveNext){
                                        // set form default state
                                        $scope.singleCashReceiptPaymentForm.$setPristine();
                                        $scope.unSelectInvNo();
                                        $scope.clearInvNo();
                                        $rootScope.showLoader(false);
                                    }
                                }
                                
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                        }
                    });
            }
        };

        // Popup to open the form to edit cash receipt
        $scope.showCashReceiptFormForEdit = function (cashRecID) {
            var diaryModal = $uibModal.open({
                templateUrl: "modules/cash_receipt/cash_receipt_form_modal.html",
                controller: 'editCashReceiptController',
                resolve: {
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([{
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }]);
                    },
                    CASH_RECEIPT_ID: function () {
                        return cashRecID;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
            diaryModal.closing = function () {
                preventDefault();

            };
            diaryModal.result.then(function () {
                $scope.fetchNewData(INV_NO);
            }, function () {
                $scope.fetchNewData(INV_NO);
            });
        };

        $scope.deleteCashReceipt = function(cashRecID){
            swal({
                    title: "Are you sure ?",
                    text: "YOU ARE ABOUT TO REMOVE THIS ENTRY! ",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/CashReceipt/'+ cashRecID)
                        .success(function (response) {
                            if(response.error){
                                swal(response.result.message || 'Error while deleting this entry.', '', "error");
                                // Notification.error(response.result.message || 'Error while deleting this entry.');
                            } else{
                                Notification.success(response.result.message || 'Entry has been successfully removed.');
                            }
                            $scope.onAccountAmounts = [];
                            $scope.onAccountCreditMemos = [];
                            $scope.onAccountCreditPayments = [];
                            $scope.fetchNewData(INV_NO);
                        })
                        .error(function (err) {
                            Notification.error(response.result.message | 'Error while deleting this entry.');
                            });
                    } else {
                        return false;
                    }
                });
        };

        $scope.deleteCashReceiptcheck = function(cashRecID){
            swal({
                    title: "Are you sure ?",
                    text: "YOU ARE ABOUT TO REMOVE THIS ENTRY! ",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.get('/api/deleteCashReceiptcheck/'+ cashRecID)
                        .success(function (response) {
                            if(response.error){
                                swal(response.result.message || 'Error while deleting this entry.', '', "error");
                                // Notification.error(response.result.message || 'Error while deleting this entry.');
                            } else{
                                Notification.success(response.result.message || 'Entry has been successfully removed.');
                            }
                            $scope.onAccountAmounts = [];
                            $scope.onAccountCreditMemos = [];
                            $scope.onAccountCreditPayments = [];
                            $scope.fetchNewData(INV_NO);
                        })
                        .error(function (err) {
                            Notification.error(response.result.message | 'Error while deleting this entry.');
                            });
                    } else {
                        return false;
                    }
                });
        };

        $scope.reverseCashReceipt = function(cashRecID){
            swal({
                    title: "Are you sure ?",
                    text: "YOU ARE ABOUT TO REVERSE THIS ENTRY! ",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.get('/api/reverseCashReceipt/'+ cashRecID)
                        .success(function (response) {
                            if(response.error){
                                swal(response.result.message || 'Error while reversing this entry.', '', "error");
                            } else{
                                Notification.success(response.result.message || 'Entry has been successfully reversed.');
                            }
                            $scope.onAccountAmounts = [];
                            $scope.onAccountCreditMemos = [];
                            $scope.onAccountCreditPayments = [];
                            $scope.fetchNewData(INV_NO);
                        })
                        .error(function (err) {
                            Notification.error(response.result.message | 'Error while reversing this entry.');
                            });
                    } else {
                        return false;
                    }
                });
        };


        $scope.deleteAccountCredit = function(accCreditId){
            swal({
                    title: "Are you sure ?",
                    text: "YOU ARE ABOUT TO REMOVE THIS ENTRY! ",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it!",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/AccountCredits/'+ accCreditId)
                        .success(function (response) {
                            if(response.error){
                                swal(response.result.message || 'Error while deleting this entry.', '', "error");
                            } else{
                                Notification.success(response.result.message || 'Entry has been successfully removed.');
                            }
                            $scope.onAccountAmounts = [];
                            $scope.onAccountCreditMemos = [];
                            $scope.onAccountCreditPayments = [];
                            $scope.fetchNewData(INV_NO);
                        })
                        .error(function (err) {
                            Notification.error(response.result.message | 'Error while deleting this entry.');
                            });
                    } else {
                        return false;
                    }
                });
        };

        $scope.insuranceCompanyModal = function() {
            $rootScope.showLoader(true);

            var modalInstance = $uibModal.open({
                templateUrl: 'modules/insurance/claim_rep/add/addModal.html',
                controller: 'addClaimrepController',
                size: 'lg',
                resolve: {
                    fromModal : function(){
                        return true;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            {
                                name: 'ngPatternRestrict',
                                files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                            }]);
                    },
                }
            });

            modalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (data) {
                if(data){
                    $scope.cashReceiptData.paidBy = data;
                    $scope.cashReceiptData.paidBy.NAME = $scope.cashReceiptData.paidBy.CompanyName;
                    $scope.cashReceiptData.paidBy.resType = 2;
                    $scope.assignedPaidBy = true;

                    $scope.cashReceiptData.pullPaidBy = false;
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.creditRefundPopUp = function(){
            if($scope.assignedPaidBy){
                $rootScope.refundCredit({
                    source_id : $scope.cashReceiptData.paidBy.id,
                    source_type : $scope.cashReceiptData.paidBy.resType == 1 ? 'attorney' : 'insuranceCompany'
                }, function(err, success){
                    if(success){
                        $scope.fetchNewData(INV_NO);
                    }
                });
            }
        }

        $scope.isCheckOrRefNoUsed = function(type, value){
            if(type && value && $scope.cashReceiptData.attorney_firms){
                $scope.check_no_validate_message = '';
                $scope.ref_no_validate_message = '';
                $scope.isLoading = true;
                $http.post(apiUrl+'/api/validate-check-or-reference-number',{ATTORNEY : $scope.cashReceiptData.attorney_firms, type : type, checkOrRefNo : value}).success(function(response){
                    if(response.error){
                        Notification.error(response.result.message);
                    }else{
                        if(response.result.isCheckNoUsed){
                            $scope.check_no_validate_message = 'Check number already taken. Use on account credit instead!';
                            $scope.is_check_ref_no_validate = true;
                        }else if(response.result.isRefNoUsed){
                            $scope.ref_no_validate_message = 'Reference number already taken. Use on account credit instead!';
                            $scope.is_check_ref_no_validate = true;
                        }else{
                            $scope.is_check_ref_no_validate = false;
                        } 
                    }
                    $scope.isLoading = false;
                });
            }
        }
        $scope.onChange=function(){
            if($scope.cashReceiptData.checkNumber){
                $scope.is_check_ref_no_validate = true;
            }else{
                $scope.is_check_ref_no_validate = false;
                $scope.ref_no_validate_message = '';
                $scope.check_no_validate_message = '';
            }
        }
        $scope.checkIsRandomPayor = function(){
            if($scope.cashReceiptData.isRandomPayor){
                $scope.cashReceiptData.pullPaidBy =false;
                $scope.unSelectPaidBy();
                $scope.assignedPaidBy=true;
            }
        }
        $scope.assignRandomPayorAsPaidBy = function(){
            if($scope.cashReceiptData.isRandomPayor){
                $scope.cashReceiptData.paidBy= {};
                $scope.cashReceiptData.paidBy.id = $scope.cashReceiptData.randomPayor;
                $scope.cashReceiptData.paidBy.resType = 3;
            }
        }
    }
]);
