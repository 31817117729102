angular.module('deitz')
            .controller('holdreviewController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$uibModal',
                    'Notification',
                    '$filter',
                    '$timeout',
                    '$q',
                    'SweetAlert',
                    '$state',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $uibModal,
                            Notification,
                            $filter,
                            $timeout,
                            $q,
                            SweetAlert,
                            $state
                        ) {

        $scope.totalRecords = 0;       
        $scope.lookingFor = $state.params.lookingFor && $state.params.lookingFor == 'audio' ? 'audio' : 'reporter';
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive','V':'Videographer'};

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
        $http.get(apiUrl + '/api/getJobStatus').then(function(response) { 
            $scope.JobStatusList = response.data.result.data;  
            $scope.JobStatusList.unshift({name:'All'});
        });
        $http.get(apiUrl + '/api/get-biller-list').success(function(response) {
            $scope.BillerList=response.result
        });
        $scope.selectedBillerIDs = [];
        $scope.selectedJobStatusIDs = [];

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $scope.report = {
            startDate : null,
            endDate : null
        };

        $scope.assignmentTypes = [
            {'type':'','name':'-- ALL JOBS --'},
            {'type':'audio','name':'AUDIO JOBS'},
            {'type':'geico','name':'GEICO JOBS'},
            {'type':'reporter','name':'RESOURCE JOBS'} 
        ];
        
        /* Notes browse datatable*/
        $scope.dtInstance = {};

        $scope.reloadData = function () {
            $scope.dtInstance.rerender();
        };

        $scope.resetSearch = function(){
            $scope.dtInstance.DataTable.state.clear();
            $scope.lookingFor = 'reporter';
            $scope.report = {
                startDate : null,
                endDate : null
            };
            $scope.reloadData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                // map your server's response to the DataTables format and pass it to

                data.where = {};
                data.exVoid = {}; 
                data.orwhere = {};

                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));                $scope.inProcess = true;
                
                commonFactory.showDataTable('/api/holdReviewInvoice', data).success(function (res) {
                    if (res.error) {
                        $scope.reloadData();
                    }
                    else {
                        $scope.totalRecords = res.recordsFiltered;                            
                        $scope.selectAll = true;
                        $scope.invoiceArray = {};
                        callback(res);
                    }
                }).error(function (err) {
                    if (err.error !== "token_not_provided") {
                        $scope.reloadData();
                    }
                });
            })
            .withOption('processing', true)
            .withLanguage({
                "sEmptyTable": "NO INVOICE AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ INVOICES",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING INVOICE FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            // .withOption('searching', false)
            .withOption('order', [15, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            })
            .withOption('initComplete', function(row) 
            {
                for (var i=0; i < row.aoPreSearchCols.length; i++)
                {
                    if (row.aoPreSearchCols[i].sSearch)
                    {
                        var input=$('#invtbl').find('thead > tr').eq(1).find('th').eq(i).find('input');
                        $(input).val(row.aoPreSearchCols[i].sSearch);
                        row.oInit.lightColumnFilterOptions[i].attr = {};
                        row.oInit.lightColumnFilterOptions[i].attr.value = row.aoPreSearchCols[i].sSearch;
                    }
                }
            })
            .withLightColumnFilter({
               '1': {
                    type: 'text',
                    attr: {}
               },   
                               
               '3': {
                    type: 'text',
                    attr: {}
                },
               '4': {
                   type: 'text',
                   attr: {}
                },
                '5': {
                    type: 'text',
                    attr: {}
                },
                '6': {
                   type: 'text',
                   attr: {}
                },
                '7': {
                   type: 'text',
                   attr: {}
                },
                '8': {
                    type: 'text',
                    attr: {}
                 },
                '9': {
                   type: 'text',
                   attr: {}
                },
                '10': {
                    type: 'text',
                    attr: {}
                 }                                       
                                                 
            });

        $scope.dtColumns = [];
       $scope.ori_invoice = {};        
            
        $scope.dtColumns.push(          
            DTColumnBuilder.newColumn('original_invoice_no').withTitle('Invoice No <hr> Legacy No').withOption('searchable', true).withOption('search', '522123').withOption("width", "8%").renderWith(function(data,type,full,meta){
                
                var dte_inv = moment(full.dte_invoice, 'YYYY-MM-DD');
                var dte_month = dte_inv.format('M');
                var dte_year  = dte_inv.format('YYYY');  
                
                /* 
                    This commented code is checking the invoice is from prior month or not..If so then we were not allowing invoice to be edited. 
                    Mark asked us to remove prior month restriction to edit the invoice...because he wanted to edit some of the fields even the invoice is from prior month.
                    So from the browse screen we removed that restriction but yes in the edit screen we are allowing only some of the fileds to edit... NOT ALL.
                */
                // if(full.is_voided == '1' || !( moment().format('M')== dte_month && moment().format('YYYY')== dte_year) || full.is_void_invoice == '1'){
                    legacy_invoice_id = '';
                    cid = '--';    
                   
                    cid = cid  +  parseInt(full.cnt_original + 1);                  
                 
                if(full.legacy_invoice_id){
                    legacy_invoice_id = '<br><hr>'+full.legacy_invoice_id;
                }
                if(full.is_voided == '1'  && (full.INV_NO == full.original_invoice_no)){
                    return  full.original_invoice_no+'<br>'+
                            '<small><i>ORIGINAL</i></small>'+cid +
                            legacy_invoice_id;                               
                }
                else if((full.is_void_invoice == '1' || (full.is_voided == '1'  && full.INV_NO != full.original_invoice_no)) && full.is_cod == '1'){                        
                    return  full.original_invoice_no+'<br>'+
                            '<small><i>COD</i></small>' +cid +
                            legacy_invoice_id;                                
                }
                else if(full.is_void_invoice == '1' || (full.is_voided == '1'  && full.INV_NO != full.original_invoice_no)){                        
                    
                    return  full.original_invoice_no+'<br>'+
                            '<small><i>CREDIT MEMO</i></small>' +cid +
                            legacy_invoice_id;                                
                }
                else if(full.is_void_invoice == '0' && full.is_voided == '0' && (full.INV_NO != full.original_invoice_no)){ 
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank">'+full.original_invoice_no+'</a><br>'+
                            '<small>ACTIVE</small>'+cid +
                            legacy_invoice_id;   
                }
                else{
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })" target="_blank">'+full.original_invoice_no+'</a> '+ legacy_invoice_id;   
                }
            }),
           // DTColumnBuilder.newColumn('legacy_invoice_id').withTitle('LEGACY NO').withOption('searchable', true).withOption("width", "6%"),
            DTColumnBuilder.newColumn('last_invoice_sent_date').withTitle('Last Invoice Sent Date').withOption('searchable', true).withOption("width", "6%"),
            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No <hr> Legacy No').withOption('searchable', true).withOption("width", "4%").renderWith(function(data,type,full,meta){

                var data = '<a class="pull-left" href="javascript:void(0);" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + ' })" target="_blank">'+full.JOB_NO+'</a>';

                legacy_job_no = '';
                if(full.diary_data.legacy_job_no){
                    legacy_job_no = '<br><hr><span style="font-size:12px;">'+full.diary_data.legacy_job_no+'</span>';
                }
                
                return (data) ? data + legacy_job_no : '';
            }),  
            DTColumnBuilder.newColumn('diary_data.disposition').withTitle('Court Status').withOption('searchable', true).withOption("width", "4%").withOption('data', 'diary_data.disposition').withOption('name', 'diaryData.disposition'),
            DTColumnBuilder.newColumn('claim_representative.claimRepWithInsuranceComp').withTitle('Claim Rep').withOption("width", "12%").withOption('searchable', true).withOption('data', 'claim_representative.claimRepWithInsuranceComp').withOption('name', 'ClaimRepresentative.CR_CompanyName').renderWith(function (data, type, full, meta) {
                if(data){
                    return full.claim_representative.CR_CompanyName+" | "+full.claim_representative.claimRepName;
                }
                return '';
            }),
            DTColumnBuilder.newColumn('attorney_firms.SHORT_NAME').withTitle('Sold To').withOption("width", "8%").withOption('searchable', true).withOption('data', 'attorney_firms.SHORT_NAME').withOption('name', 'attorneyFirms.SHORT_NAME').renderWith(function (data, type, full, meta) {
                if (data) {
                    data = escapeString(data);
                    var billing_firm = '<a class="pull-left" href="javascript:void(0);" ui-sref="firms.manage.step_one({id : ' + full.attorney_firms.id + ' })" target="_blank">';
                     billing_firm += "<div uib-tooltip='"+full.attorney_firms.NAME+ " | " +full.attorney_firms.PHONE+ " | " +full.attorney_firms.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    billing_firm += '</a>';
                    return billing_firm ;
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('diary_data.attorney_firm.SHORT_NAME').withTitle('Scheduling Firm').withOption("width", "8%").withOption('searchable', true).withOption('data', 'diary_data.attorney_firm.SHORT_NAME').withOption('name', 'diaryData.attorneyFirm.SHORT_NAME').renderWith(function (data, type, full, meta) {
                if (data) {
                    data = escapeString(data); 
                    var billing_firm = "<div uib-tooltip='"+full.diary_data.attorney_firm.NAME+ " | "+full.diary_data.attorney_firm.PHONE+ " | " +full.diary_data.attorney_firm.ADDR_LINE1+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    return billing_firm;
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('attorney_name.NAME').withTitle('Attorney Name').withOption("width", "8%").withOption('searchable', true).withOption('data', 'attorney_name.NAME').withOption('name', 'attorneyName.NAME').renderWith(function (data, type, full, meta) {
                if (data !== null && data !== undefined) {
                    data = escapeString(data); 
                    var attorney_firm = "<div uib-tooltip='"+full.attorney_name.NAME+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:20}}</div>";
                    return attorney_firm;
                } 
                return '-';
            }),
            DTColumnBuilder.newColumn('job.CAPTION').withTitle('Case Name').withOption("width", "8%").withOption('searchable', true).withOption('data', 'job.CAPTION').withOption('name', 'job.CAPTION').renderWith(function (data, type, full, meta) {
                if (data) {
                    var caption = escapeString(data);
                    var indNumFlag = false;
                    
                    if(full.IndexNumber)
                    {
                        indNumFlag = true;
                    }

                    var billing_firm = "<div uib-tooltip='"+data+"'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+caption+"' | limitTocustom:13}} <br> <a ng-if='"+indNumFlag+"' href='{{depoHost}}/autologin.php?userid={{auth_user.email}}&password={{auth_user.depo_psd}}&script=display_on_elaw.php?indexNumber="+full.IndexNumber+"' target='_blank' title='Display On Elaw'>Elaw</a></div>";
                    return billing_firm;
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('witness').withTitle('Witnesses').withOption('searchable', true).withOption('data', 'witness').notSortable().withOption('name', 'witnessData.witness.NAME').renderWith(function (data, type, full, meta) {
                if (data) {
                    return $filter('uppercase')(data, true);
                } else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('INV_AMT').withTitle('Invoice Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }

            }),
            DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }

            }),
            DTColumnBuilder.newColumn('PAID_DT').withTitle('Paid Date').withOption('searchable', true).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data && $filter('formatDate')(data) != 'Invalid Date') {
                    return $filter('formatDate')(data);
                }
                else {
                    return '-'
                }
            }),
            DTColumnBuilder.newColumn('INV_BAL_DUE').withTitle('Balance Due').withOption('searchable', false).withClass('font-bold').withOption("width", "5%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn('dte_invoice').withOption("width", "5%").withTitle('Invoice Date').withOption('searchable', false).withOption('data', 'dte_invoice').withOption('name', 'INVOICE_tbl.dte_invoice').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(full.dte_invoice).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '-';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '-';
                }

            }),
            
            DTColumnBuilder.newColumn('diary.businessunit.name').withTitle('Business Unit').withOption('searchable', true).withOption('defaultContent', '-').withOption("width", "6%")
            
        );       
	
		$scope.toggleJobType = function(JOB_NO, euo_statement_of){
            
            SweetAlert.swal({
                title: "Are you sure want to change?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes, Change it",
                cancelButtonText: "No!",
                closeOnConfirm: true,
                closeOnCancel: true
            }, function(callback){
                if(callback){
                    $rootScope.showLoader(true);
                    return $http.post(apiUrl + '/api/toggle-job-type', {JOB_NO : JOB_NO, euo_statement_of : euo_statement_of})
                    .then(function(response) {
                        $scope.reloadData();
                        $rootScope.showLoader(false);
                    });
                }
            });
        };

        $scope.niceBytes = function (x){
            
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if(x === 1) return '1 byte';

              let l = 0, n = parseInt(x, 10) || 0;

              while(n >= 1000 && ++l){
                  n = n/1000;
              }
              //include a decimal point and a tenths-place digit if presenting
              //less than ten of KB or greater units
              return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        };
    }
]);
