angular.module('deitz')
    .controller('sendExhibitEmailController', [
        '$scope',
        '$rootScope',
        '$http',
        'apiUrl',
        'Notification',
        '$state',
        '$timeout',
        '$filter',
        'commonFactory',
        '$stateParams',
        '$uibModal',
        'JOB_NO',
        'exhibitIds',
        'caption',
        '$uibModalInstance',
        function ($scope,
                  $rootScope,
                  $http,
                  apiUrl,
                  Notification,
                  $state,
                  $timeout,
                  $filter,
                  commonFactory,
                  $stateParams,
                  $uibModal,
                  JOB_NO,
                  exhibitIds,
                  caption,
                  $uibModalInstance) {

            $scope.JOB_NO = JOB_NO;
            $scope.exhibitIds = exhibitIds;
            $scope.caption = caption;
            $scope.toEmail;
            $scope.fromEmail;
            $scope.emailSubject = "Exhibits For: Lexitas# " + JOB_NO+ " - " + caption;
            $scope.emailBody;
            $scope.maxEmailBodyLength = 150;
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
            };

            $scope.ValidateEmail = function (mail) {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
                    return (true)
                }
                return (false)
            }

            $scope.submit_send_mail = function () {
                if ($scope.ValidateEmail($scope.toEmail)) {
                    $rootScope.showLoader(true);
                    var req_data = {
                        'fromEmail': $scope.fromEmail,
                        'toEmail': $scope.toEmail,
                        'subject': $scope.emailSubject,
                        'body': $scope.emailBody,
                        'JOB_NO': $scope.JOB_NO,
                        'exhibitIds': $scope.exhibitIds
                    }
                    commonFactory.post('/api/send-email-to-exhibit', req_data).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {

                        } else {
                            $scope.closePopupWindowSimple();
                            Notification.success('Email sent successfully!');
                        }
                    }).error(function (err) {
                        $rootScope.showLoader(false);
                    });
                }
            };


        }]);