angular.module('deitz').controller('addfirm_sort2Controller', [

    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification','fromModal','$uibModalInstance',
    function($scope, $state, $rootScope, codeFactory, commonFactory, Notification,fromModal,$uibModalInstance
    ){

        $scope.firm_sort2 = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Firm Sort2';

        $scope.addfirm_sort2 = function(firm_sort2, isValid) {
            $scope.formSubmit = true;  
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/firm_sort2', $scope.firm_sort2)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) { 
                            if (response.status == 200) {
                                Notification.success("Firm Sort2 added successfully");
                                $state.go("code_tables.firm_sort2_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
