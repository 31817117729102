angular.module('deitz').controller('editfirm_sort2Controller', [
    '$scope', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', 'plaintiffId', '$uibModalInstance',
    function(
    $scope, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, plaintiffId, $uibModalInstance
    ) {

        $scope.firm_sort2 = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Firm Sort2';
        
        if ($state.params && $state.params.id) {
            var id = $state.params.id;
            commonFactory.get('/api/firm_sort2/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.data) {
                        $scope.firm_sort2.name = response.result.data.name || '';
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        }

        $scope.addfirm_sort2 = function(firm_sort2, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = $state.params.id;
                commonFactory.put('/api/firm_sort2/' + id, $scope.firm_sort2)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Firm Sort2 updated successfully");
                                $state.go("code_tables.firm_sort2_browse");
                            }else if(response.status == 400){
                                Notification.error(response.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
