angular.module('deitz').controller('addPaymentTypeController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        $uibModalInstance
    ){

        $scope.paymentType = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Payment Type';
        $scope.isAddMode = true; // Flag to indicate the add mode

        $scope.closeModal = function(){
            $uibModalInstance.close();
        }

        $scope.addPaymenttype = function(paymenttype, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/payment-types', $scope.paymentType)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Payment type added successfully");

                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.payment_type.id);
                                }else{
                                    $state.go("code_tables.payment_type_browse");
                                }
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

        $scope.updateSlug = function(name) {
            if (name) {
                $scope.paymentType.slug = name
                    .toLowerCase()
                    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
                    .replace(/\s+/g, '_')        // Replace spaces with hyphens
                    .trim();                     // Trim whitespace
            } else {
                $scope.paymentType.slug = '';
            }
        };

    }]);
