angular.module('deitz').controller('editlocationController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    'venueId',
    '$uibModalInstance',
    function(
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        venueId,
        $uibModalInstance
    ){

        $scope.location = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Venue';
        $scope.modalId = venueId;

        $scope.closeModal = function(){
            $uibModalInstance.close();
        };
        //Edit Mode of Location.
        if (($state.params && $state.params.id) || venueId) {
            var id = venueId ? venueId : $state.params.id;
            commonFactory.get('/api/locations/' + id + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.location_detail) {
                        $scope.location.name = response.result.location_detail.name || '';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of Location
        $scope.addLocation = function(location, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = venueId ? venueId : $state.params.id;
                commonFactory.put('/api/locations/' + id, $scope.location)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Venue updated successfully");
                                
                                if(fromModal == true){ 
                                    $uibModalInstance.close(response.result.location_update.id);
                                }else{
                                    $state.go("code_tables.location_browse");
                                }
                                
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
