angular.module('deitz')
    .controller('ImportEmailTemplateController', [
        '$scope', 
        '$rootScope',
        'codeFactory' , 
        'DTOptionsBuilder',
        'DTColumnBuilder',
        'RolesFactory',
        'commonFactory',
        '$compile',
        '$uibModal',
        '$filter',
        'apiUrl',
        'Notification',
        '$window',
        '$uibModalInstance',
        'previewContent',        
        '$http',
        '$sce',
        'Upload',
        '$state',
    function (
        $scope,
        $rootScope,
        codeFactory,
        DTOptionsBuilder,
        DTColumnBuilder,
        RolesFactory,
        commonFactory,
        $compile,
        $uibModal,
        $filter,
        apiUrl,
        Notification,
        $window,
        $uibModalInstance,
        previewContent,
        $http,
        $sce,
        Upload,
        $state) {
            
            $scope.title = 'Select Zip file for import';  
            $scope.formSubmit = false;   
            $scope.formData = {};             
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;           

            // Call the function to fetch processed HTML on load            
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.dismiss({ isChanged : true});
            }; 
            
            $scope.clickUpload = function () {
                $("#zipFile").trigger('click');
            }           

            $scope.sendzipnumber = function(isValid) {
                $scope.formSubmit = true;              
                if (isValid && $scope.isNameUnique == true) {
                    $rootScope.showLoader(true); // Show loader while processing           
                    Upload.upload({
                        url: apiUrl+'/api/import-email-template',
                        data: $scope.formData
                    }).then(function (resp) {  
                        //$('#ZipForm')[0].reset();                                                   
                        if(resp.data.status == 200){                                
                            $rootScope.showLoader(false);                                       
                            Notification.success('Template imported Successfully');
                            $scope.closePopupWindowSimple();
                            $state.go('index.email_template_browse', null, { reload: true });                        
                        }else{                               
                            $rootScope.showLoader(false); 
                            Notification.error(resp.data.result.message || "Something went wrong!");
                        }
                    },function(err){
                        $rootScope.showLoader(false); 
                        Notification.error('Something went wrong!');                      
                    });
                }
            };

             // Function to check if the name is unique
        $scope.isNameUnique = true;
        $scope.checkUniqueName = function(zipform,name) {
            // Check if we're in edit mode (i.e., if there's an ID in the state params)
           
                // If it's a new record, proceed with the usual uniqueness check
                if (name) {
                    $http.post(apiUrl + '/api/check_email_templte_name_unique', { name: name })
                        .then(function(response) {
                            $scope.isNameUnique = response.data.isUnique;
                            zipform.$setValidity("unique", $scope.isNameUnique);
                        })
                        .catch(function(error) {
                            console.error('Error checking name uniqueness:', error);
                        });
                }
          
        };
    }]);