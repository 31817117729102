angular.module('deitz').controller('accountReceivableNotesController', [

    '$scope', '$rootScope', '$http', 'apiUrl', 'DTOptionsBuilder', 'DTColumnBuilder', 'commonFactory', '$compile', '$uibModal', 'Notification', '$filter', '$timeout', '$q', '$stateParams', '$state', 'invoiceFactory',
    function (
        $scope, $rootScope, $http, apiUrl, DTOptionsBuilder, DTColumnBuilder, commonFactory, $compile, $uibModal, Notification, $filter, $timeout, $q, $stateParams, $state, invoiceFactory
    ) {


        $scope.notes = [];
        $scope.invoices = [];
        $scope.formData = {selectedValue : 'attorney'};
        $scope.assignedFirm = false;
        $scope.invoiceArray = {};
        $scope.attId = $stateParams.attId ? $stateParams.attId : null;
        $scope.id = null;
        
        if($stateParams.clmId){
            $scope.clmId = $stateParams.clmId;
            $scope.formData.selectedValue = 'claimrep';
        }
        $scope.cpid = 0;
        if($stateParams.cpid){
            $scope.cpid = $stateParams.cpid;          
        }
        if($stateParams.attNameId){
            $scope.attNameId = $stateParams.attNameId;
            $scope.formData.selectedValue = 'individual';
        }

        

        // Getting all Collectors 
        $http.get(apiUrl + '/api/collector').success(function(res){
            $scope.collectors = res.result;
        });

        //Get aging statements agin data 
        $scope.getAging = function(){
            var agingParams = {
                ids : [],
                onlyAging : true
            };

            if($scope.formData.selectedValue == 'attorney' && $scope.formData.firm){
                agingParams.ids[0]= $scope.formData.firm ? $scope.formData.firm.id : null;
                agingParams.type = 'attorneyFirm';
                
                $http.post(apiUrl + '/api/getStatementData', agingParams).then(function(response) {
                    $scope.isAging = true;
                    $scope.agingOfStatemetns = response.data.result[0].aging;
                });        
            }
        };

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
        $scope.getAttorneyNameData = function(attname_id){

            $rootScope.showLoader(true);
            $http.get(apiUrl+'/api/geteditattorneydata_att_edit/' + attname_id )
                .success(function (response) {
                    if(! response.error){
                        var data = response.result.attorney_detail;
                        $scope.formData.attname = data;
                        $scope.formData.attNameId = data.id;
                        $scope.formData.email = data.Email_Address ||'';
                        $scope.getFreshData();
                        $scope.reloadInvoicesData();
                        $rootScope.showLoader(false);
                    } else{
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(true);
                });
        };

        $scope.owed_bal_due = 0;

        $scope.getAttorneyData = function(attId){

            $rootScope.showLoader(true);
            commonFactory.get('/api/attorneys/'+attId)
                .success(function (response) {
                    if(! response.error){
                        var data = response.result.data;
                        $scope.formData.firm = data;
                        $scope.formData.firm.payor_email_verified_date = data.payor_email_verified_date ? moment(data.payor_email_verified_date).format('MM/DD/YYYY') : '';
                        $scope.formData.firm.last_spoken_date = data.last_spoken_date ? moment(data.last_spoken_date).format('MM/DD/YYYY') : '';
                        $scope.formData.firm.inv_based_next_call_date = data.inv_based_next_call_date ? moment(data.inv_based_next_call_date).format('MM/DD/YYYY') : '';
                        $scope.formData.firm.next_call_date = data.next_call_date ? moment(data.next_call_date).format('MM/DD/YYYY') : '';

                        $scope.formData.address1 = data.ADDR_LINE1 ||'';
                        $scope.formData.address2 = data.ADDR_LINE2 ||'';
                        $scope.formData.city = data.CITY ||'';
                        $scope.formData.state = data.STATE ||'';
                        $scope.formData.phone = data.PHONE ||'';
                        $scope.formData.zip = data.ZIP ||'';
                        $scope.formData.cell = data.CELL ||'';
                        $scope.formData.fax = data.FAX ||'';
                        $scope.formData.email = data.EmailAddress ||'';
                        $scope.formData.NOTES_3 = data.NOTES_3 ||'';
                        $scope.oldCollectorID = angular.copy(data.collector_id);
                        $scope.assignedFirm = true;
                        $scope.owed_bal_due = data.BAL_DUE || 0;
                        $scope.getFreshData();
                        $scope.reloadInvoicesData();
                    } else{
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(true);
                });
        };

        if($scope.attId){
            localStorage.setItem('attId',$scope.attId);
            $scope.getAttorneyData($scope.attId);
        }

        if($scope.attNameId){
            localStorage.setItem('attNameId',$scope.attNameId);
            $scope.getAttorneyNameData($scope.attNameId);
        }


        
        $scope.collecterDateUpdate= function(){
          
            var last_spoken_date = $scope.formData.firm.last_spoken_date 
            var payor_email_verified_date = $scope.formData.firm.payor_email_verified_date 

            last_spoken_date = last_spoken_date ? moment(last_spoken_date ).format('YYYY/MM/DD') : null;
            payor_email_verified_date = payor_email_verified_date ? moment(payor_email_verified_date ).format('YYYY/MM/DD') : null;
            
            $rootScope.showLoader(true); 
            $http.post(apiUrl+'/api/update-collector-firm-date',{firmID : $scope.formData.firm.id, last_spoken_date : last_spoken_date,payor_email_verified_date:payor_email_verified_date}).success(function(res){                    
                $rootScope.showLoader(false);
                if (!res.error) {
                    Notification.success(res.result.message);
                }else{
                    Notification.error(res.result.message);
                }
            
            });
        };

        $scope.invBaseNxtCallDateUpdate = function () {
            var inv_based_next_call_date = $scope.formData.firm.inv_based_next_call_date;

            inv_based_next_call_date = inv_based_next_call_date ? moment(inv_based_next_call_date).format('YYYY/MM/DD') : null;

            $rootScope.showLoader(true);
            $http.post(apiUrl + '/api/update-inv-based-next-call-date', {
                firmID: $scope.formData.firm.id,
                inv_based_next_call_date
            }).success(function (res) {
                $rootScope.showLoader(false);
                if (!res.error) {
                    Notification.success(res.result.message);
                } else {
                    Notification.error(res.result.message);
                }

            });
        };

        $scope.nxtCallDateUpdate = function () {
            var next_call_date = $scope.formData.firm.next_call_date;

            next_call_date = next_call_date ? moment(next_call_date).format('YYYY/MM/DD') : null;
            let next_call_date_45 = next_call_date ? moment().add(45, 'days').format('YYYY/MM/DD') : null;
            if (next_call_date_45 > next_call_date) {
                $scope.formData.firm.inv_based_next_call_date = next_call_date
            }

            $rootScope.showLoader(true);
            $http.post(apiUrl + '/api/update-next-call-date', {
                firmID: $scope.formData.firm.id,
                next_call_date
            }).success(function (res) {
                $rootScope.showLoader(false);
                if (!res.error) {
                    Notification.success(res.result.message);
                } else {
                    Notification.error(res.result.message);
                }

            });
        };

        $scope.onSelectclaimrepcompany = function(item, model, label){
            console.log('address_id = '+item.AddressID)
            console.log('cpid = '+item.id)                        
            $scope.cpid= item.id;   
            $scope.claimrepListlist(item.id);          
           
        };
        $scope.onSelectClaimrepSearch = function(item, model, label){
            $scope.formData.claimrepc = item.insurance_comp                     
            // $scope.cpid= item.insurance_comp.id;   
            $scope.formData.claimrep = item.ClaimRepID
            
            console.log(item.ClaimRepID,item.insurance_comp.id)
            $scope.onSelectClaimrep(item.insurance_comp.id);   
            $scope.claimrepListlist(item.insurance_comp.id);          
           
        };
        $scope.onSelectFirm = function(item, model, label){
            localStorage.setItem('attId',item.id);
            if($scope.attId == item.id){
                $rootScope.showLoader(true);
                $scope.getAttorneyData(item.id);
                
            }

            $stateParams = {
                attId : item.id,
                clmId : null,
                attNameId:null
            };
            $state.go('.',$stateParams);
            $stateParams.attId = item.id;
            $scope.attId = item.id;

        };

        $scope.onSelectIndividual = function(item, model, label){
            localStorage.setItem('attNameId',item.id);
            if($scope.attNameId == item.id){
                $rootScope.showLoader(true);
                $scope.getAttorneyNameData(item.id);
                
            }


            $stateParams = {
                attNameId : item.id,
                attId : null,
                clmId : null
            };
            $state.go('.',$stateParams);
            $stateParams.attNameId = item.id;
            $scope.attNameId = item.id;

        };


        /**
         *@function editAttorneyFirm
        */
       $scope.editAttorneyFirm = function(attorneyFirmId){

            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/firms/add_attorney_firm_modal.html",
                data: {pageTitle: 'Edit Firm'},
                controller: 'firmsController',
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    attorneyFirmId : function(){
                        return attorneyFirmId;
                    }
                }
            });

            editAttorneyModal.result.then(function (firmData) {
                $scope.getAttorneyData(firmData.id);
                
            }, function () {
                console.log("Modal Dismissed!!!");
            });
        }

        $scope.claimrepListlist = function (address_id) {
            $http.post(apiUrl + '/api/search-claimrep-name-cp', {address_id : address_id}).then(function(response) { 
                $scope.claimrepList = response.data.result;  
            });
        }
        //Getting Claimrep list
        /*$scope.claimrepList = function () {
            return $http.post(apiUrl + '/api/search-claimrep-name-cp', {cpid : 10}).then(function (response) {
                return response.data.result.map(function (item) {
                    $scope.claimrepList =  item;
                });
            });
        }*/
        

        //Getting ClaimRepresentative List
        $scope.InsuranceCompanyList = function (val) {
            $scope.searchingInsCompany = true;
            return $http.post(apiUrl + '/api/search-insurance-company1', {query_string: val}).then(function(response) {

                $scope.searchingInsCompany = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.ClaimRepList = function (val) {
            $scope.searchingInsCompany = true;
            return $http.post(apiUrl + '/api/search-claimrep-name-with-insurance-comp', {query_string: val}).then(function(response) {

                $scope.searchingInsCompany = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };
        


        $scope.getClaimrepDatacompany = function(cid){

            $rootScope.showLoader(true);
            $http.get(apiUrl+'/api/claimrepresentative/' + cid + '/edit')
                .success(function (response) {
                    if(! response.error){
                        var data = response.result.claim_rep;
                        $scope.formData.claimrepc = data;                        
                        $scope.assignedFirm = true;
                        $scope.getFreshData();
                        $scope.reloadInvoicesData();
                        $rootScope.showLoader(false);
                    } else{
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(true);
                });
        };


        $scope.getClaimrepData = function(clmId){

            $rootScope.showLoader(true);
            $http.get(apiUrl+'/api/claimrepresentativename/' + clmId + '/edit')
                .success(function (response) {
                    if(! response.error){
                        var data = response.result.claim_rep_name;
                        $scope.formData.claimrep = data.ClaimRepID;
                        $scope.formData.address1 = data.CR_Address1 ||'';
                        $scope.formData.address2 = data.CR_Address2 ||'';
                        $scope.formData.city = data.CR_City ||'';
                        $scope.formData.state = data.CR_State ||'';
                        $scope.formData.phone = data.CR_Phone ||'';
                        $scope.formData.zip = data.CR_ZipCode ||'';
                        $scope.formData.cell = data.CR_CellPhone ||'';
                        $scope.formData.fax = data.CR_Fax ||'';
                        $scope.formData.email = data.CR_EmailAddress ||'';
                        $scope.assignedFirm = true;
                        $scope.getFreshData();
                        $scope.reloadInvoicesData();
                        $rootScope.showLoader(false);
                    } else{
                        $rootScope.showLoader(false);
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(true);
                });
        };

        if($scope.clmId){
            localStorage.setItem('clmId',$scope.clmId);
            $scope.getClaimrepData($scope.clmId);
            $http.get(apiUrl+'/api/claimrepresentativename/' + $scope.clmId + '/edit')
            .success(function (response) {
                if(! response.error){
                    var data = response.result.claim_rep_name;                    
                    $scope.claimrepListlist(data.claimrepresentativeaddress_id); 
                    console.log('claimrepresentativeaddress_id ='+ data.claimrepresentativeaddress_id);
                }
            });
            $scope.formData.claimrep = $scope.clmId;
            console.log('climidid='+$scope.formData.claimrep);
         
        }

        if($scope.cpid){
            console.log('companyid='+$scope.cpid);
            localStorage.setItem('cpid',$scope.cpid);
            $scope.getClaimrepDatacompany($scope.cpid);
        }


        $scope.onSelectClaimrep = function(cpid){ 
            localStorage.setItem('clmId',$scope.formData.claimrep);
            localStorage.setItem('cpid',cpid);

            console.log('onchange climrep ='+$scope.formData); 
                              
            if($scope.clmId == $scope.formData.claimrep){
                $rootScope.showLoader(true);
                $scope.getClaimrepData($scope.formData.claimrep);                       
            }
            if($scope.formData.claimrep){  
                
                   $stateParams = {
                    attId : null,
                    attNameId:null,
                    clmId : $scope.formData.claimrep,
                    cpid : cpid
                };
            $state.go('.',$stateParams);
            }

            $stateParams.clmId = $scope.formData.claimrep;
            $scope.clmId = $scope.formData.claimrep;           
            $scope.cpid = cpid;            
           
        };

        $scope.claimrepModal = function (id) {
            $rootScope.showLoader(true);
            if(id){
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/insurance/claim_rep_name/add/claim_rep_modal.html',
                    controller: 'editClaimrepnameController',
                    windowClass: 'full-width-model',
                    resolve: {
                        fromModal : function(){
                            return true;
                        },
                        claimrepId : function(){
                            return id;
                        },
                        claim_rep_data: function () {
                            return null;
                        },
                        loadPlugin:function($ocLazyLoad){
                            return $ocLazyLoad.load([
                                {
                                    name: 'ngPatternRestrict',
                                    files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                                }]);
                        }
                    }
                });
            }
            
            modalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (claimrepresentative) {
                if(claimrepresentative){
                    $scope.getClaimrepData(claimrepresentative.ClaimRepID);
                }

            }, function () {
                console.log("Modal Dismissed!!!");
            });
        };

        $scope.resetForm = function(type){
            if($scope.assignedFirm){         
                $scope.formData = {};
                $scope.formData.selectedValue = type;
                $scope.assignedFirm = false;
                $scope.notes = [];
                $scope.invoices = [];
                $scope.reloadNotesData();
                $scope.reloadInvoicesData();
                
            }
        };


        //Getting attname List
        $scope.individualsList = function (val) {
            $scope.searchingIndividual = true;
            return $http.get(apiUrl + '/api/getattorneyslist',  {
                params: {
                    name: val
                }
            }).then(function(response) {

                $scope.searchingIndividual = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.resetType = function(type){

            $scope.formData = {};
            $scope.formData.selectedValue = type;
            $scope.assignedFirm = false;
            $scope.notes = [];
            $scope.invoices = [];
            $scope.reloadNotesData();
            $scope.reloadInvoicesData();

            var params = {
                attId : null,
                clmId : null,
                cpid  : null,
                attNameId:null
            };

            if(type == 'attorney'){
                if(localStorage.getItem('attId')){
                    params = {
                        attId : localStorage.getItem('attId'),
                        attNameId:null,
                        clmId : null,
                        cpid  : null
                    };
                    $state.go('.',params,{reload:true});
                }
            }
            else if(type=='claimrep'){
                if(localStorage.getItem('clmId') || localStorage.getItem('cpid')){
                    params = {
                        attId : null,
                        attNameId:null,
                        clmId : localStorage.getItem('clmId'),
                        cpid  : localStorage.getItem('cpid')
                    };
                    $state.go('.',params,{reload:true});

                }
            }
            else if(type=='individual'){
                if(localStorage.getItem('attNameId')){
                    params = {
                        attId : null,
                        attNameId : localStorage.getItem('attNameId'),
                        clmId : null,
                        cpid  : null
                    };
                    $state.go('.',params,{reload:true});
                }
            }

           

        }

        $scope.getFreshData = function(){
            $rootScope.showLoader(true);

            async.parallel([function(cb){

                $scope.getNotes(cb);

            }], function(err, resp){

                $rootScope.showLoader(false);
            });
        };

        /*
         Get notes based on attorney firm
         params : attorney_id

         * */
        $scope.getNotes = function(cb){
            $scope.notes = [];

            if($scope.formData.selectedValue == 'attorney'){
                var requestUrl ='/api/AccountsReceivableAttorneyNotes/notesOfAttorney';
                var getNotesParams = {
                    attorney_id: $scope.formData.firm.id
                };
            }else if($scope.formData.selectedValue == 'individual'){
                var requestUrl ='/api/AccountsReceivableAttNameNotes/notesOfAttName';
                var getNotesParams = {
                    attname_id: $scope.formData.attname.id
                };
            }
            else{
                var requestUrl ='/api/AccountsReceivableClaimRepNotes/notesOfClaimrep';
                var getNotesParams ={
                    claimrep_id :$scope.formData.claimrep
                };
            }

            commonFactory.post(requestUrl , getNotesParams)
                .success(function(response) {
                    if (response && response.result && response.result.notes) {
                        $scope.notes = response.result.notes;
                        $timeout(function(){
                            $scope.reloadNotesData();
                        },1000);
                    }
                    cb(null, true);
                })
                .error(function(err) {
                    cb(err, false);
                });
        };
        /*
         ================================================================================================================
         ============================== Start : Notes datatable =========================================================
         ================================================================================================================
         */

        /* Notes browse datatable*/
        $scope.dtInstanceNotes = {};

        $scope.reloadNotesData = function (){
            $scope.dtInstanceNotes.rerender();
        };

        $scope.resetNotesSearch = function(){
            $scope.dtInstanceNotes.DataTable.state.clear();
            $scope.reloadNotesData();
        };

        $scope.headerCompiled = false;

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtNotesOptions = DTOptionsBuilder.fromFnPromise(function() {
            var defer = $q.defer();
            defer.resolve($scope.notes);
            return defer.promise;
        })
            .withOption('processing', true)
            .withLanguage({

                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ NOTES",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 NOTES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL NOTES)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ NOTES",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO NOTES FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', false)
            .withOption('stateSave', false)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(5)
            .withOption('lengthMenu', [
                [5, 10, 25, 50],
                [5, 10, 25, 50]
            ])
            .withOption('order', [3, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });


        $scope.dtNotesColumns = [];

        if($scope.formData.selectedValue =='attorney'){

            $scope.dtNotesColumns.push(

                DTColumnBuilder.newColumn('ARAFNote').withTitle('Note').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '30%').renderWith(function(data, type, full, meta){
                    var row = '';
                    if(full.is_have_attachment=='1'){
                        icon="paperclip";
                        if(full.is_zip_attached =='1'){
                            icon="file-archive-o";
                        }
                        row += '<a href="javascript:void(0);" ng-click="getAttorneyArAttachmentUrl('+full.id+')"  ><i uib-tooltip="Document was included." class="fa fa-'+icon+'" aria-hidden="true"></i></a> &nbsp;';
                    }
                    else if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                    return row += data;
                }),
                DTColumnBuilder.newColumn('created_by.name').withTitle('Created By').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '20%').renderWith(function (data, type, full, meta) {
                    if(data){
                        return data;
                    }
                    if(full.send_by_cron){
                        return "-- CRON --";
                    }
                    return "";
                }),
                DTColumnBuilder.newColumn('ReceivedBy').withTitle('Received By').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '25%').renderWith(function (data, type, full, meta) {
                    if(full.emails.length !=0){
                        html='';
                        angular.forEach(full.emails, function(value, key){
                            html+='<span>'+value.type+' : '+value.email+'</span> ';
                            var textclass='warning';
                            
                                if(value.last_event){
                                    switch(value.last_event.event){
                                        case 'delivered':
                                        case 'transmitting_page':
                                            textclass='warning'
                                            break;
                                        case 'open':
                                        case 'fax_completed':
                                            textclass='success'
                                            break;
                                        case 'bounce':
                                            textclass='danger'
                                            break;
                                        case 'spamreport':
                                        case 'retry_scheduled':
                                            textclass='secondary'
                                            break;
                                        break;
                                        default:
                                            textclass='warning'
                                            break;
                                    }
                                    html+='<td><a class="text-'+textclass+'" href="javascript:void(0);" ng-click="viewEventLog('+value.id+')"><b><i class="fa fa-eye"></i></b></a><td>';
                                }

                            
                            if(full.emails.length !==(key+1)){
                                html+='<hr style="margin:5px;border-top:1px solid #e7e7e7;">';
                            }
                        });
                        return html;    
                    }
                    else if(data != null){
                        return "<span style='display:block;max-width:100%;word-break:break-all;'>"+data+"</span>";
                    }
                    return "";
                }),
                DTColumnBuilder.newColumn('ARAFNoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return moment(data).format('MM/DD/YYYY');
                    } else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('ARAFReminderDate').withTitle('Reminder Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return $filter('formatDate')(data);
                    } else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption('width', '15%').renderWith(function (data, type, full, meta) {
                    var row='';

                    //Edit button
                    row += '<a ng-if="havePermission(\'account_receivable_notes_attorney\',\'edit\')" tooltip-trigger="focus mouseenter" uib-tooltip="Edit" tooltip-placement="top" class="btn btn-xs btn-primary" ng-click="openEditNoteModal(' + full.id + ')"><i class="fa fa-pencil"></i></a> &nbsp;';

                    //Change note status to hide
                    if(full.HideNote == 'Y'){
                        row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Undo" tooltip-placement="top" class="btn btn-xs btn-danger" ng-click="toggleNote(' +full.id+',\'N\')"><i class="fa fa-eye-slash"></i></a></td>';
                    }else{
                        row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Done" tooltip-placement="top" class="btn btn-xs btn-success" ng-click="toggleNote(' + full.id +',\'Y\')"><i class="fa fa-eye"></i></a></td>';
                    }

                    //Delete button
                    row += '&nbsp; <a ng-if="havePermission(\'account_receivable_notes_attorney\',\'delete\')" tooltip-trigger="focus mouseenter" uib-tooltip="Delete" tooltip-placement="top" class="btn btn-xs btn-danger" ng-click="deleteNote(' + full.id + ')"><i class="fa fa-trash"></i></a></td>';
                    return row;

                })
            );

        }
        else if($scope.formData.selectedValue == 'claimrep'){

            $scope.dtNotesColumns.push(

                DTColumnBuilder.newColumn('ARCRNote').withTitle('Note').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '40%').renderWith(function(data, type, full, meta){
                    var row = '';

                    if(full.is_have_attachment=='1'){
                        row += '<a href="javascript:void(0);" ng-click="getClaimRepArAttachmentUrl('+full.id+')"  ><i uib-tooltip="Document was included." class="fa fa-paperclip" aria-hidden="true"></i></a> &nbsp;';
                    }
                    else if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                    return row += data;
                }),
                DTColumnBuilder.newColumn('created_by.name').withTitle('Created By').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '20%'),
                DTColumnBuilder.newColumn('ARCRNoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return moment(data).format('MM/DD/YYYY');
                    } else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('ARCRReminderDate').withTitle('Reminder Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return $filter('formatDate')(data);
                    } else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption('width', '15%').renderWith(function (data, type, full, meta) {
                    var row='';

                    //Edit button
                    row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Edit" tooltip-placement="top" class="btn btn-xs btn-primary" ng-click="openEditNoteModal(' + full.id + ')"><i class="fa fa-pencil"></i></a> &nbsp;';

                    //Change note status to hide
                    if(full.HideNote == 'Y'){
                        row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Undo" tooltip-placement="top" class="btn btn-xs btn-danger" ng-click="toggleNote(' +full.id+',\'N\')"><i class="fa fa-eye-slash"></i></a></td>';
                    }else{
                        row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Done" tooltip-placement="top" class="btn btn-xs btn-success" ng-click="toggleNote(' + full.id +',\'Y\')"><i class="fa fa-eye"></i></a></td>';
                    }

                    //Delete button
                    row += '&nbsp; <a tooltip-trigger="focus mouseenter" uib-tooltip="Delete" tooltip-placement="top" class="btn btn-xs btn-danger" ng-click="deleteNote(' + full.id + ')"><i class="fa fa-trash"></i></a></td>';
                    return row;

                })
            );

        }
        else if($scope.formData.selectedValue == 'individual'){

            $scope.dtNotesColumns.push(

                DTColumnBuilder.newColumn('ARINote').withTitle('Note').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '40%').renderWith(function(data, type, full, meta){
                    var row = '';

                    if(full.is_have_attachment=='1'){
                        row += '<a href="javascript:void(0);" ng-click="getIndividualArAttachmentUrl('+full.id+')"  ><i uib-tooltip="Document was included." class="fa fa-paperclip" aria-hidden="true"></i></a> &nbsp;';
                    }
                    else if(full.isInvoicesIncluded === 'Y'){
                        row += '<i uib-tooltip="Invoices was included into the statements." class="fa fa-paperclip" aria-hidden="true"></i> &nbsp;';
                    }
                    return row += data;
                }),
                DTColumnBuilder.newColumn('created_by.name').withTitle('Created By').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '20%'),
                DTColumnBuilder.newColumn('ARINoteDateTime').withTitle('Created Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return moment(data).format('MM/DD/YYYY');
                    } else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn('ARIReminderDate').withTitle('Reminder Date').withOption('defaultContent', '').withOption('searchable', true).withOption('width', '10%').renderWith(function (data, type, full, meta) {

                    if (data) {
                        return $filter('formatDate')(data);
                    } else {
                        return '-'
                    }

                }),
                DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption('width', '15%').renderWith(function (data, type, full, meta) {
                    var row='';

                    //Edit button
                    row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Edit" tooltip-placement="top" class="btn btn-xs btn-primary" ng-click="openEditNoteModal(' + full.id + ')"><i class="fa fa-pencil"></i></a> &nbsp;';

                    //Change note status to hide
                    if(full.HideNote == 'Y'){
                        row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Undo" tooltip-placement="top" class="btn btn-xs btn-danger" ng-click="toggleNote(' +full.id+',\'N\')"><i class="fa fa-eye-slash"></i></a></td>';
                    }else{
                        row += '<a tooltip-trigger="focus mouseenter" uib-tooltip="Done" tooltip-placement="top" class="btn btn-xs btn-success" ng-click="toggleNote(' + full.id +',\'Y\')"><i class="fa fa-eye"></i></a></td>';
                    }

                    //Delete button
                    row += '&nbsp; <a tooltip-trigger="focus mouseenter" uib-tooltip="Delete" tooltip-placement="top" class="btn btn-xs btn-danger" ng-click="deleteNote(' + full.id + ')"><i class="fa fa-trash"></i></a></td>';
                    return row;

                })
            );

        }

        $scope.openAddNoteModal = function(){
            $rootScope.showLoader(true);
            var addNoteModal = $uibModal.open({
                templateUrl: "modules/accountReceivableNotes/addNote.html",
                controller: 'addAccountReceivableNotesAttorneyController',
                resolve: {
                    ATTORNEY: function () {
                        return $scope.formData.firm ? $scope.formData.firm : null ;
                    },
                    ClaimRep:function(){
                        return $scope.formData.claimrep ? $scope.formData.claimrep : null;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            addNoteModal.opened.then(function(){
                $rootScope.showLoader(false);
            });

            addNoteModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.getNotes(function(){
                        $rootScope.showLoader(false);
                    });
                }

            });
        };

        $scope.openEditNoteModal = function(id){
            $rootScope.showLoader(true);
            var editNoteModal = $uibModal.open({
                templateUrl: "modules/accountReceivableNotes/addNote.html",
                controller: 'editAccountReceivableNotesAttorneyController',
                resolve: {
                    ID : function () {
                        return id;
                    },
                    NoteType : function(){
                        return $scope.formData.selectedValue =='attorney' ? 'attorney' : ($scope.formData.selectedValue =='claimrep') ? 'claimrep' : 'individual';
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });

            editNoteModal.opened.then(function(){
                $rootScope.showLoader(false);
            });

            editNoteModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.getNotes(function(){
                        $rootScope.showLoader(false);
                    });
                }

            });
        };

        $scope.toggleNote = function(id , mode){
            var swltext = (mode == "Y") ? 'You are going to complete this note.' : 'You are going to undo this note.';
            swal({
                    title: "Are you sure ?",
                    text: swltext,
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        var requestUrl = ($scope.formData.selectedValue == 'attorney') ? '/api/AccountsReceivableAttorneyNotes/hide-note' : ($scope.formData.selectedValue == 'claimrep') ? '/api/AccountsReceivableClaimRepNotes/hide-note' : '/api/AccountsReceivableAttNameNotes/hide-note';
                        $http.post(apiUrl+requestUrl, {id:id,mode:mode})
                            .success(function(response) {
                                if (response && response.result) {
                                    Notification.success("Note updated successfully");

                                    $scope.getNotes(function(){
                                        $rootScope.showLoader(false);
                                    });

                                }else{
                                    Notification.error('Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };

        $scope.deleteNote = function(id){

            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete the note.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        var requestUrl = ($scope.formData.selectedValue == 'attorney') ? '/api/AccountsReceivableAttorneyNotes/'+id : ($scope.formData.selectedValue == 'claimrep') ?  '/api/AccountsReceivableClaimRepNotes/'+id : '/api/AccountsReceivableAttNameNotes/'+id;
                        commonFactory.delete(requestUrl)
                            .success(function(response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.getNotes(function(){
                                        $rootScope.showLoader(false);
                                    });
                                }else{
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };


        /*
            ============================== End : Notes datatable ===========================================================
            ================================================================================================================
            ============================== Start : Invoices datatable ======================================================
         * */


        /* Notes browse datatable*/
        $scope.dtInstanceInvoices = {};

        $scope.reloadInvoicesData = function () {
            $scope.getAging();
            $scope.dtInstanceInvoices.rerender();
        };

        $scope.resetInvoicesSearch = function(){
            $scope.arExcludePaids = false;
            $scope.arExcludeVoids = false;
            $scope.dtInstanceInvoices.DataTable.state.clear();
            $scope.reloadInvoicesData();
        };

        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            if (data.is_void_invoice == '1' || data.is_voided=='1') {
                angular.element(row).addClass('voided-invoice');
            }
            $compile(angular.element(row).contents())($scope);

        };

        $scope.dtInvoicesOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                    // map your server's response to the DataTables format and pass it to
                    
                    if($scope.arExcludePaids){
                        data.arExPaid = true;
                    }

                    if($scope.arExcludeVoids){
                        data.arExVoid = true;
                    }

                    if($scope.formData.selectedValue == 'attorney'){
                        data.attorney_id= $scope.formData.firm ? $scope.formData.firm.id : null;
                    }else if($scope.formData.selectedValue == 'claimrep'){
                        data.ClaimRepID= $scope.formData.claimrep ? $scope.formData.claimrep : null;

                    }else if($scope.formData.selectedValue == 'individual'){
                        data.attorneyname_id= $scope.formData.attname ? $scope.formData.attname.id : null;
                    }

                    data.search.value = data.search.value.replace(/ /g, "%");
                    
                    commonFactory.post('/api/invoice/search-invoice-by-note-type', data)
                        .success(function(response) {
                            if (response.error) {
                                $scope.reloadInvoicesData();
                            }
                            else {
                                if(response.data.length > 0){
                                    $scope.invoiceArray = {};
                                    $scope.selectAll = true;
                                }
                                callback(response);
                            }
                        })
                        .error(function(err) {
                            if (err.error !== "token_not_provided") {
                                $scope.reloadInvoicesData();
                            }
                        });

                })
            .withOption('processing', true)
            .withLanguage({

                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ INVOICES",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL INVOICES)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ INVOICES",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO INVOICES FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
            .withOption('stateSave', true)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(10)
            .withOption('lengthMenu', [
                [10, 25, 50, 100],
                [10, 25, 50, 100]
            ])
            .withOption('order', [1, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            });

        $scope.dtInvoicesColumns = [];
        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAllInvoice(selectAll)">';

        $scope.dtInvoicesColumns.push(

            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().withOption('width', "2%").renderWith(function (data, type, full, meta) {
                    const is_checked = $rootScope.batchInvoiceArray?.filter(function (o) {
                        return o.text == full.INV_NO;
                    })[0];
                    $scope.invoiceArray[full.invoiceID] = full;
                    $scope.invoiceArray[full.invoiceID].is_checked = (typeof is_checked === 'undefined') ? false : true;

                    if (!$scope.invoiceArray[full.invoiceID].is_checked) {
                        $scope.selectAll = false;
                    }
                    return '<input type="checkbox" icheck ng-model="invoiceArray[' + full.invoiceID + '].is_checked" ng-change="toggleBatchInvoice(invoiceArray[' + full.invoiceID + '].is_checked,' + full.INV_NO + ', true)">';
                }).withOption('searchable', false),
            DTColumnBuilder.newColumn('INV_NO').withTitle('Inv #').withOption('defaultContent', '').withOption('searchable', true).withOption('width', "7%").renderWith(function(data,type,full,meta){

                var dte_inv = moment(full.dte_invoice, 'YYYY-MM-DD');
                var dte_month = dte_inv.format('M');
                var dte_year  = dte_inv.format('YYYY');

                if(full.is_voided == '1' || !( moment().format('M')== dte_month && moment().format('YYYY')== dte_year) || full.is_void_invoice == '1'){
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })">'+full.original_invoice_no+'</a>';
                   
                }else{
                    return  '<a class="pull-left" href="javascript:void(0);" ui-sref="invoice.edit({invoice_id : ' + full.invoiceID + ' })">'+full.original_invoice_no+'</a>';
                }
            }),
            DTColumnBuilder.newColumn('JOB_NO').withTitle('Job No').withOption('defaultContent', '').withOption('searchable', true).withOption('width', "7%").renderWith(function(data,type,full,meta){
                return  '<a class="pull-left" href="javascript:void(0);" ui-sref="diary.editcase.step_one({id : ' + full.JOB_NO + ' })">'+full.JOB_NO+'</a>';
            }),
            DTColumnBuilder.newColumn('diary_data.disposition').withTitle('Court Status').withOption('defaultContent', '').withOption('name', 'diaryData.disposition').withOption('searchable', true).withOption('width', "8%"),
            DTColumnBuilder.newColumn('INV_DT').withTitle('Inv Dt').withOption('defaultContent', '').withOption('searchable', true).withOption('width', "7%"),
            DTColumnBuilder.newColumn('INV_AMT').withTitle('Inv Amt').withOption('defaultContent', '').withOption('searchable', true).withOption('width', "7%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn('PAID_AMT').withTitle('Paid Amt').withOption('defaultContent', '').withOption('searchable', true).withOption('width', "7%").renderWith(function (data, type, full, meta) {
                if (data) {
                    return '$' + $filter('number')(data, 2);
                } else {
                    return '$' + $filter('number')(0, 2);
                }
            }),
            DTColumnBuilder.newColumn('attorney_name.name').withTitle('Attorney').withOption('defaultContent', '').withOption('name', 'attorneyName.name').withOption('searchable', true).renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var attorney_name = "<div uib-tooltip='"+data+ "'  tooltip-trigger='focus mouseenter'  tooltip-placement='top'>{{'"+data+"' | limitTocustom:40}}</div>";
                    return attorney_name;
                } else {
                    return '---';
                }
            }),
            DTColumnBuilder.newColumn('claim_representative.claimRepName').withTitle('Claim Rep').withOption('defaultContent', '').withOption('name', 'ClaimRepresentative.ClaimRepFirstName').withOption('searchable', true).renderWith(function(data, type, full, meta){
                if (data) {
                    data = escapeString(data);
                    var claimRepName = "<div uib-tooltip='"+data+ "' tooltip-trigger='focus mouseenter' tooltip-placement='top'><a ui-sref='insurance.claim_rep_name_edit({id:"+full.claim_representative.ClaimRepID+"})''>{{'"+data+"' | limitTocustom:13}}</a></div>";
                    return claimRepName;
                } else {
                    return '---';
                }
            }),
            DTColumnBuilder.newColumn('diary_data.DATE_TAKEN').withTitle('Job Date').withOption('defaultContent', '').withOption('name', 'diaryData.DATE_TAKEN').withOption('searchable', true),
            DTColumnBuilder.newColumn('advised_payment_check_dt').withTitle('Advised Check Dt').withOption('searchable', true).withOption('data', 'advised_payment_check_dt').withOption('name', 'INVOICE_tbl.advised_payment_check_dt').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(full.advised_payment_check_dt).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '---';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('advised_payment_check_num').withTitle('Advised Check Num').withOption('searchable', true).withOption('data', 'advised_payment_check_num').withOption('name', 'INVOICE_tbl.advised_payment_check_num').renderWith(function (data, type, full, meta) {
                if(data && full.advised_payment_check_filepath) {
                   return '<a tooltip-trigger="focus mouseenter" ng-click="viewAdvisedCheckFile('+full.invoiceID+')" uib-tooltip="Show File">'+data+'</a>';
                }
                else if(data){
                    return data;
                } 
                else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('advised_payment_cashed_dt').withTitle('Advised Cashed Dt').withOption('searchable', true).withOption('data', 'advised_payment_cashed_dt').withOption('name', 'INVOICE_tbl.advised_payment_cashed_dt').renderWith(function (data, type, full, meta) {
                if(data) {
                    var dte = moment(full.advised_payment_cashed_dt).format('MM/DD/YYYY');
                    if(dte == 'Invalid date'){
                        return '---';
                    }else{
                        return dte;
                    }
                } 
                else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn('NOTES').withTitle('Inv Note').withOption('searchable', true).withOption("width", "16%").withOption('data', 'NOTES').withOption('name', 'INVOICE_tbl.NOTES').renderWith(function (data, type, full, meta) {
                if(data) {
                   return data
                } 
                else {
                    return '---';
                }

            }),
            DTColumnBuilder.newColumn(null).withTitle('Action').withOption('searchable', false).withOption("width", "8%").notSortable().renderWith(function (data, type, full, meta) {

                const is_printed = (full.is_printed == '1') ? 'Re-Print' : 'Print';

                const print_class = (full.is_printed == '1') ? 'text-navy' : 'text-danger';

                var advised_payment_mail_button='';
                if(full.is_have_advised_payment=='1'){
                    advised_payment_mail_button='<li uib-tooltip="Send Email" ng-if="havePermission(\'invoice\',\'update\')">' +
                    '<a class="btn btn-xs btn-default" ng-click="sendAdvisedPaymentInfo(' + full.invoiceID + ','+full.INV_NO+')"><i class="fa fa-envelope text-navy"></i> &nbsp; Advised Payment Email </a>'+
                '</li>'
                }

                var bindButtons = '<div class="btn-group btn-sm" uib-dropdown style="color:black!important">' +
                                        '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                                        '<ul role="menu" uib-dropdown-menu="" style="left: -80px!important;">'+
                                            '<li uib-tooltip="Send Fax" ng-if="havePermission(\'invoice\',\'update\')">' +
                                                '<a class="btn btn-xs btn-default" ng-click="sendInvoice(' + full.INV_NO + ',\'fax\')"><i class="fa fa-fax text-navy"></i> &nbsp; Fax </a>'+
                                            '</li>'+
                                            '<li uib-tooltip="Send Email" ng-if="havePermission(\'invoice\',\'update\')">' +
                                                '<a class="btn btn-xs btn-default" ng-click="sendInvoice(' + full.INV_NO + ',\'email\')"><i class="fa fa-envelope text-navy"></i> &nbsp; Email </a>'+
                                            '</li>'+
                                            '<li uib-tooltip="Advised Payment" ng-if="havePermission(\'invoice\',\'update\')">' +
                                                '<a class="btn btn-xs btn-default" ng-click="openAdvisedPaymentModal(' + full.invoiceID +')"><i class="fa fa-money text-navy"></i> &nbsp; Advised Payment </a>'+
                                            '</li>'+
                                            advised_payment_mail_button+
                                            '<li uib-tooltip="' + is_printed + '">' +
                                                '<a class="btn btn-xs btn-default" ng-click="showInvoicePrint(' + full.INV_NO + ')"><i class="fa fa-print '+ print_class +'"></i> &nbsp; Print </a>'+
                                            '</li>'+
                                        '</ul>' +
                                    '</div>';

                                    return bindButtons;

            })
        );

        $scope.toggleBatchInvoice = function (is_checked, invoiceNo, isSingle) {
            const invoiceObject = {text: invoiceNo};
            const index = $rootScope.batchInvoiceArray.filter(function (o) {
                return o.text == invoiceNo;
            })[0];


            if (is_checked) {
                if (typeof index == 'undefined') {
                    $rootScope.batchInvoiceArray.push(invoiceObject);
                }
            }
            else {

                if (typeof index !== 'undefined') {

                    $rootScope.batchInvoiceArray = $rootScope.batchInvoiceArray.filter(function (item) {
                        return item.text != invoiceNo;
                    });
                }
            }

            if(isSingle){
                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!                
            }

            //check the main checkbox is selected
            var keepGoing = true;
            $timeout(function () {
                angular.forEach($scope.invoiceArray, function (value, key) {
                    if (keepGoing) {
                        if (!$scope.invoiceArray[key].is_checked) {
                            $scope.selectAll = false;
                            keepGoing = false;
                        }
                        else {
                            $scope.selectAll = true;
                        }
                    }

                });
            });
        };

        $scope.toggleAllInvoice = function (isChecked) {

            angular.forEach($scope.invoiceArray, function (value, key) {
                $scope.invoiceArray[key].is_checked = isChecked;
                $scope.toggleBatchInvoice(isChecked, $scope.invoiceArray[key].INV_NO);
                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
            });
        }

        $scope.$on('reloadInvoiceBrowseBroadcastEvent', function (event, data) {
            $scope.reloadInvoicesData();
        });

        $scope.showFirmInvoices = function(att_id, firm_name){
            invoiceFactory.searchData = {
                searchValue : {
                    id : att_id,
                    NAME: firm_name
                },
                selectedValue : "BILLING_FIRM"
            };

            $state.go('invoice.browse');
        };

        $scope.showClaimRepInvoices = function(claim_rep,claim_rep_name){ 
            invoiceFactory.searchData = {
                searchValue : claim_rep,
                selectedValue : "ClaimRepID",
                claimRepName: claim_rep_name
            };

            $state.go('invoice.browse');
        };

        $scope.saveARNote = function(){
            
            $rootScope.showLoader(true);
            commonFactory.post('/api/save-ar-note', {id : $scope.formData.firm.id, NOTES_3 : $scope.formData.NOTES_3})
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (!response.error) {
                        $scope.getAttorneyData($scope.formData.firm.id);
                        Notification.success(response.result.message);
                    }else{
                        Notification.error(response.result.message);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false); 
                });
        }

        $scope.updateCollector = function(newCollectorID){
            var oldCollector = '';
            var newCollector = '';
            var ar_note = '';
            
            if(!$scope.oldCollectorID)
            {
                oldCollector = 'N/A';
            }
            if(!newCollectorID)
            {
                newCollector = 'N/A';
            }
            
            angular.forEach($scope.collectors, function(value, key) {
                if(value.id && value.id == $scope.oldCollectorID)
                {
                    oldCollector = value.name;
                }

                if(value.id && value.id == newCollectorID)
                {
                    newCollector = value.name;
                }
            });

            if(oldCollector && newCollector)
            {
                ar_note = 'Collector has been changed from '+oldCollector+' to '+newCollector+' for this firm.' 
            }
            else
            {
                ar_note = 'Collector has been changed for this firm.'
            }

            swal({
                title: "Are you sure",
                text: "You want to change collector for this firm ?",
                type: "warning",
                html: true,
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) { 
                    $rootScope.showLoader(true); 
                    $http.post(apiUrl+'/api/update-collector',{firmID : $scope.formData.firm.id, newCollectorID : newCollectorID}).success(function(res){                    
                        $rootScope.showLoader(false);
                        if (!res.error) {
                            $scope.oldCollectorID = newCollectorID;
                            Notification.success(res.result.message);

                            $rootScope.showLoader(true);
                            $scope.formData.AR_ReminderDate = moment().format('YYYY-MM-DD');
                            $scope.formData.ARNote = ar_note;
                            $scope.formData.HideNote = 'N';
                            $scope.formData.AttorneyFirm_FK = $scope.formData.firm.NAME_KEY;
                            $scope.formData.attorney_id = $scope.formData.firm.id;
                            
                            var requestUrl = '/api/AccountsReceivableAttorneyNotes';

                            commonFactory.post( requestUrl , $scope.formData ).success(function(response) {
                                if (response) {
                                    if (response.status == 200) {
                                        Notification.success("Note created successfully");
                                        $rootScope.showLoader(false);
                                        $scope.getNotes(function(){
                                            $rootScope.showLoader(false);
                                        });
                                    } else{
                                        $rootScope.showLoader(false);
                                    }
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                        }else{
                            Notification.error(res.result.message);
                        }
                    
                    });
                }else{
                    $scope.formData.firm.collector_id = $scope.oldCollectorID;
                    $scope.$apply();
                }
            });
        }

        $scope.showInvoicePrint = function (invoice_id) {

            if (angular.isArray(invoice_id)) {
                var invoices = invoice_id;
            }
            else {
                var invoices = [{text: invoice_id}];
            }
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup.html",
                controller: 'viewPrintInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoices;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });


            invoiceModal.result.then(function (is_invoice_printed) {
                if (is_invoice_printed) {
                    var data = {invoice_ids: invoices};
                    //need to update the flag for print
                    commonFactory.post('/api/invoice/mark-as-printed', data).success(function (response) {
                        //console.log(response);
                        $scope.reloadInvoicesData();
                    }).error(function () {
                        $scope.reloadInvoicesData();
                    });
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        }

        $scope.getAttorneyArAttachmentUrl = function(id){
            if(id){
                $http.get(apiUrl + '/api/get-attorney-ar-attachment-url', {
                    params: {
                        id: id,
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.getClaimRepArAttachmentUrl = function(id){
            if(id){
                $http.get(apiUrl + '/api/get-claim-ar-attachment-url', {
                    params: {
                        id: id,
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.getIndividualArAttachmentUrl = function(id){
            if(id){
                $http.get(apiUrl + '/api/get-attname-ar-attachment-url', {
                    params: {
                        id: id,
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.openAdvisedPaymentModal =function(inv_no){
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/accountReceivableNotes/advised_payment_modal.html",
                controller: 'advisedPaymentController',
                resolve: {
                    inv_no: function () {
                        return inv_no;
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.reloadInvoicesData();
                    $rootScope.showLoader(false);
                }

            });
        }
        $scope.viewAdvisedCheckFile = function(id){
            if(id){
                $http.get(apiUrl + '/api/get-advised-checkfile-url', {
                    params: {
                        id: id
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }
        $scope.viewEventLog = function(id){
           
            var viewEventModal = $uibModal.open({
                templateUrl: "modules/accountReceivableNotes/ar_notes_email_events.html",
                controller : function($scope, $uibModalInstance,$http,apiUrl){
                    $scope.closePopupWindow = function(){
                        $uibModalInstance.close();
                    }
                    $scope.getDateFormat= function(date){
                        var dte = moment(date).format('MM/DD/YYYY hh:mm:ss A');
                        if (dte == 'Invalid date') {
                            return '-';
                        } else {
                            return dte;
                        }
                    }
                    $scope.getEmailEvent = function(){
                        $rootScope.showLoader(true);
                        $http.get(apiUrl + '/api/get-ar-email-events/'+id)
                        .then(function(response) {
                            $rootScope.showLoader(false);
                            if(!response.data.error){
                               $scope.events=response.data.results.events;
                            }else{
                                Notification.error(response.data.message);
                            }
                        });
                    }
                    $scope.getEmailEvent();
                },
                resolve: {
                    id: function () {
                        return id;
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
        }
    }
]);
