angular.module('deitz').controller('addResourceSkillsController', [
    '$scope', '$http', 'apiUrl', '$state', '$rootScope', 'codeFactory', 'commonFactory', 'Notification', 'fromModal', '$uibModalInstance',
    function ($scope, $http, apiUrl, $state, $rootScope, codeFactory, commonFactory, Notification, fromModal, $uibModalInstance
    ) {

        $scope.formData = {};
        $scope.formSubmit = false;
        $scope.id = $state.params.id;
        $scope.title = ($scope.id) ? "SAVE" : "ADD";

        $scope.resourceTypesList
        // Getting all resources types.
        commonFactory.get('/api/additionalinfo_list')
        .success(function(response) {
            if(response.error) {
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.resourceTypesList = response.result.data;                
            }
        });

        /* Use for get level data for update*/
        if ($scope.id) {
            $rootScope.showLoader(true);
            $http.get(apiUrl + '/api/resource-skills/' + $scope.id + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    $scope.formData = response.result.resource_skills;
                    if(response.result.resource_skills.resource_types_ids && response.result.resource_skills.resource_types_ids.length){
                        var resourceTypesIds = [];
                        angular.forEach(response.result.resource_skills.resource_types_ids,function(value, key){
                            resourceTypesIds[key] = value.additional_info_id;
                        });
                        $scope.formData.resource_types_ids = resourceTypesIds;
                    }

                }).error(function (err) {
                    if (err.error) {
                        console.log(err.error)
                    }
                });
        }

        /* Use for save data */
        $scope.save = function (isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                if ($scope.id) {
                    /* API call for update data */
                    $http.put(apiUrl + '/api/resource-skills/' + $scope.id, $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Skill Updated Successfully!');
                            $state.go('code_tables.resource_skills_browse');
                        }
                    });
                } else {
                    /* API call for add data */
                    $http.post(apiUrl + '/api/resource-skills', $scope.formData).success(function (res) {
                        $rootScope.showLoader(false);
                        if (res.error) {
                            Notification.error(res.result.message);
                        } else {
                            Notification.success('Skill Added Successfully!');
                            $state.go('code_tables.resource_skills_browse');
                        }
                    });
                }

            }
        }

    }]);
