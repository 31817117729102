angular.module('deitz')
    .controller('addEmailTemplateCategoryController',
            ['$scope',
            '$rootScope',           
            'codeFactory',
            '$http',
            '$state',
            'Notification',
            'apiUrl',
            function ($scope,
            $rootScope,              
            codeFactory, 
            $http,
            $state,
            Notification,
            apiUrl) {
        $scope.page_title = $state.current.data.pageTitle;        
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;		
		$scope.formData = {};
        $scope.status = ['Active', 'Inactive'];
    
        $scope.submitEmailTemplateCategory = function (isValid) {         
            $scope.formSubmit = true;             
            $scope.formData.status = $scope.formData.status.toLowerCase();    
            if(isValid){   
                $rootScope.showLoader(true);
                if($state.params.id){                    
                    $http.put(apiUrl+'/api/email-template-category/'+$state.params.id, $scope.formData).success(function(response){						
						$rootScope.showLoader(false);
						if(response.error){
							Notification.error(response.result.message);
						}else{
							Notification.success(response.result.message);
                            $state.go('index.email_template_category_browse');						
						}
					});
                }else{
                    $http.post(apiUrl+'/api/email-template-category',$scope.formData).success(function(response){
                        $rootScope.showLoader(false);
                        if(response.error){
                            Notification.error(response.result.message);                
                        }else{                
                            Notification.success(response.result.message);
                            $state.go('index.email_template_category_browse');                
                        }            
                    });
                }
            }
        };

        //get data for edit screen
		if($state.params.id){
			$http.get(apiUrl+'/api/email-template-category/'+$state.params.id+'/edit').success(function(response){
				if(response.error){					
					Notification.error(response.result.message);
				}else{
					var resValue = response.result.data;
					$scope.formData.name = resValue.name;	
                    if (resValue.status === 'active') {
                        $scope.formData.status = 'Active';
                    } else if (resValue.status === 'inactive') {
                        $scope.formData.status = 'Inactive';
                    } 		                                         	
				}
			});
		}else{
        $scope.formData.status = 'Active';
    }      		
    }]);