angular.module('deitz').factory('cashReceiptFactory', ['$http', 'apiUrl', function($http, apiUrl) {

    var cashReceiptFactory = {};

    cashReceiptFactory.validations = {
        selectedValue: {
            required: true,
        },
        searchValue: {
            maxlength: 254,
            required: true,
        },
        INV_NO:{
            required: true,
            readonly : false,
        },
        JOB_NO:{
            readonly : true
        },
        CAPTION:{
            readonly : true
        },
        FILE_NO:{
            readonly : true
        },
        dte_invoice:{
            readonly : true
        },
        DATE_TAKEN:{
            readonly : true
        },
        attorney_firm:{
            readonly : true
        },
        INV_AMT:{
            readonly : true
        },
        PAID_DT:{
            readonly : true
        },
        PAID_AMT:{
            readonly : true
        },
        new_due_amt:{
            readonly : true
        },
        over_under_amt:{
            readonly : true
        },        
        STATUS:{
            readonly : false
        },
        InvoicePaidBy:{
            required: true,
            readonly : true
        },
        firmName:{
            required: true
        },
        ClaimNumber:{
            readonly : true
        },
        IndexNumber:{
            readonly: true
        },
        adjustment:{
            readonly: true
        },
        total_due_amt:{
            readonly: true
        },
        ar_paid_amt:{
            readonly: true
        },
        rem_amt:{
            readonly: true
        },
        new_adj_amt:{
            readonly: false,
            required: true
        },
        checkNumber:{
            required: true
        },
        RECD_TYP:{
            required: false
        },
        reference_number:{
            required: false
        }
    };

    cashReceiptFactory.messages = {

        selectedValue: {
            required: "Select option to find an invoice.",
        },
        searchValue: {
            required: "Value is required.",
            maxlength: "Value Field not be upto 254 characters."
        },
        INV_NO: {
            required : "Please select any invoice."
        },
        InvoicePaidBy:{
            required: "Please select any option",
        },
        firmName:{
            required: "Please select attorney firm",
        },
        reason:{
            required: "Please provide reason for the adjustment / write off",
        },
        RECD_TYP:{
            required: "Please select option for leave on account/ write off",
        },
        checkNumber:{
            required: "Please enter check number."
        },
        reference_number:{
            required: "Please enter reference number."
        },
        AR_paid_amt:{
            exceeded  : "Paid amount is greater than the invoice due"
        },
        adjusted_amt:{
            exceeded  : "Adjustment is greater than the invoice due."
        }
    };

    cashReceiptFactory.searchData = [];

    cashReceiptFactory.findData = {};

    cashReceiptFactory.statusOptions = [
        { key : "P", value: "Pending"},
        { key : "C", value: "Closed"}
    ];

    cashReceiptFactory.adjustmentTypes = [
        { key : "write_off", value: "Write Off" },
        { key : "adjustment", value: "Adjustment"},
        { key : "other", value: "Other"},
        { key : "open_close_invoice", value: "Open/Close Invoice"},
        { key : "none", value : "None"}
    ];

    // cashReceiptFactory.paymentTypes = [
    //     { key : "check", value: "Check"},
    //     { key : "american_express", value: "American Express"},
    //     { key : "visa_card", value: "Visa Card" },
    //     { key : "master_card", value: "Master Card" },
    //     { key : "eft", value: "EFT" },
    //     // { key : "refund", value: "Refund"},
    //     { key : "inter_company", value: "Inter company"},
    //     { key : "other", value: "Other"},
    //     { key : "check_upload", value: "Check Upload"},
    //     { key : "sync_data", value: "Sync Data"}
    // ];

    return cashReceiptFactory;

}]);