angular.module('deitz').controller('softwareController', [
    '$scope',
    '$rootScope',
    '$state',
    'commonFactory',
    'Notification',
    '$stateParams',
    'DTOptionsBuilder',
    'DTColumnBuilder',
    '$q',
    '$compile',
    function (
        $scope,
        $rootScope,
        $state,
        commonFactory,
        Notification,
        $stateParams,
        DTOptionsBuilder,
        DTColumnBuilder,
        $q,
        $compile
    ) {
        console.log('softwareController')
        $scope.includeAll = true;
        $scope.dtInstance = {};
        $scope.compileDatatable = function (row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function () {
            var table = $('#software_list').DataTable();
            table.ajax.reload();
        };
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            // .withOption('stateSave', false)
            .withDataProp('data')
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withFnServerData(function (sSource, aoData, fnCallback, oSettings) {
                var options = {
                    skip: aoData[3].value,
                    limit: aoData[4].value,
                    order: aoData[2].value,
                    search: aoData[5].value,
                    includeActive: (!$scope.includeAll && $scope.includeActive) ? $scope.includeActive : false,
                    includeInactive: (!$scope.includeAll && $scope.includeInactive) ? $scope.includeInactive : false,
                };
                $rootScope.showLoader(true);
                commonFactory.getWithPaginate('/api/software', options)
                    .success(function (response) {
                        if (response) {
                            $rootScope.showLoader(false);
                            fnCallback({
                                recordsTotal: response.recordsTotal,
                                recordsFiltered: response.count,
                                data: response.result
                            });
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            })
            .withPaginationType('simple_numbers')
            .withOption('lengthMenu', [
                [10, 15, 20, 25, 50],
                [10, 15, 20, 25, 50]
            ])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('fnPreDrawCallback', function (settings) {
                $scope.inProgress = true;
            })
            .withOption('fnDrawCallback', function () {
                $scope.inProgress = false;
            })
            // .withOption('order', [0, 'asc'])
            .withDOM('<"html5buttons"B>lTfgitp')
            .withButtons([
                {extend: 'copy'},
                {extend: 'csv'},
                {extend: 'excel', title: 'SoftwareDetails'},
                {extend: 'pdf', title: 'SoftwareDetails'}, {
                    extend: 'print',
                    customize: function (win) {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ]);

        function renderIndex(data, type, full, meta) {
            return meta.row + 1;
        }

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('name').withOption('defaultContent', '').withTitle('Name').renderWith(function (data, type, full) {
                if (full.isDefault == "1") {
                    return data + '<span class="m-l-md label label-primary">Default</span>';
                } else {
                    return data;
                }
            }),
            DTColumnBuilder.newColumn('active').withOption('defaultContent', '').withTitle('Active?').renderWith(function (data, type, full, meta) {
                if (full && full.id) {
                    var newActive = (full.active == 'YES') ? "0" : "1";
                    var btn = (full.active == 'YES') ? 'btn-success' : 'btn-danger';
                    return '<button class="ladda-button ladda-button-demo btn btn-rounded btn-outline btn-sm ' + btn + '" ng-click="changeIsActiveStatus(' + full.id + ',\'' + newActive + '\')">' + full.active + '</button>';
                }
            }),
            //DTColumnBuilder.newColumn('slug').withOption('defaultContent', '').withTitle('Slug').notSortable(),
            DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable().renderWith(function (data, type, full, meta) {
                if (data && data.id) {
                    return '<button class="btn btn-primary btn-circle" uib-tooltip="Edit" ui-sref="code_tables.software_edit({id : ' + data.id + '})" >' +
                        '<i class="fa fa-pencil"></i>' +
                        '</button> &nbsp;';
                }

            })
        ];


        $scope.changeIsActiveStatus = function (id, newStatus) {
            let status = newStatus == '1' ? 'active' : 'inactive';
            var page = $('#software_list').DataTable().page();
            swal({
                    title: "Alert!",
                    text: "Are you sure want to " + status + " this software?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        var data = {id: id, is_active: newStatus};

                        commonFactory.post('/api/software/update-is-active-status', data).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error) {
                                Notification.error(res.result.message);
                            } else {
                                Notification.success(res.result.message);
                            }
                            $('#software_list').DataTable().page(page).draw(false);
                        }).error(function (err) {
                            $rootScope.showLoader(false);
                            Notification.error(err.result.message);
                            $('#software_list').DataTable().page(page).draw(false);
                        });
                    }
                });

        };

    }]);
