angular.module('deitz').controller('editSoftwareController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    'softwareId',
    '$uibModalInstance',
    function (
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        softwareId,
        $uibModalInstance
    ) {

        $scope.software = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Edit Software';
        $scope.closeModal = function () {
            $uibModalInstance.close();
        };
        $scope.modalId = softwareId;

        //Edit Mode of software.
        if ($state.params && $state.params.id) {
            var id = softwareId ? softwareId : $state.params.id;
            commonFactory.get('/api/software/' + id + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result && response.result.software_detail) {
                        $scope.software.name = response.result.software_detail.name || '';
                        $scope.software.active = response.result.software_detail.active || '';
                        $scope.software.isDefault = response.result.software_detail.isDefault || '0';
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        }

        //Update Mode of Software
        $scope.addSoftware = function (software, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var id = softwareId ? softwareId : $state.params.id;
                commonFactory.put('/api/software/' + id, $scope.software)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Software updated successfully");

                                if (fromModal == true) {
                                    $uibModalInstance.close(response.result.software_update.id);
                                } else {
                                    $state.go("code_tables.software_browse");
                                }

                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
