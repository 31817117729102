angular.module('deitz')
            .controller('browseController',
                ['$scope',
                 '$rootScope',
                 '$state',
                 'commonFactory',
                 'invoiceFactory',
                 'Notification',
                 'DTOptionsBuilder',
                 'DTColumnBuilder',
                 '$q',
                 '$compile',
                 '$uibModal',
                 '$http',
                 'apiUrl',
                 '$window',
                    function($scope,
                             $rootScope,
                             $state,
                             commonFactory,
                             invoiceFactory,
                             Notification,
                             DTOptionsBuilder,
                             DTColumnBuilder,
                             $q,
                             $compile,
                             $uibModal,
                             $http,
                             apiUrl,
                             $window
                                ){

        
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};
        $scope.selectedFirmsIds = []; 
        $scope.firmSearching = false;
        $scope.searchFilter = {};
        $scope.filterObj = {};
        $scope.selectedBusinessUnitIDs=[];
        $scope.inv_age = "";
        $scope.inv_age_filter = "";
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        $scope.filterByCreatedDate = false;
        $scope.dateObj = {
            createdDateRange : {
                startDate : moment(),
                endDate : moment()
            }
        };

        $scope.isFilterApplied = false;
        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            // $rootScope.showLoader(true);
            $scope.firmSearching = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                // $rootScope.showLoader(false);
                $scope.firmSearching = false;
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        if ($scope.BusinessUnitsList && $scope.BusinessUnitsList.length) {
            $scope.BusinessUnitsList.unshift({ name: 'All' });
        }
        else {
            $http.get(apiUrl + '/api/getBusinessUnit').then(function (response) {
                $scope.BusinessUnitsList = response.data.result.data;
                $scope.BusinessUnitsList.unshift({ name: 'All' });

            });
        }

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S', fields:['id', 'FIRST_NAME', 'LAST_NAME', 'REP_TYP', 'NAME_KEY', 'reptypeID', 'business_unit_id']})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

        //Getting all terms 
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.termsList = res.data;
        });

        //Getting all sources from source table.
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.sourcesList=response.data.data;       
        }); 

        //Getting all status of firm.
        $http.get(apiUrl + '/api/get-status-of-firm').success(function(response) { 
            $scope.statusList=response.result;       
        }); 

        /*Getting all client level */
        $http.get(apiUrl + '/api/get_client_level').then(function(response) {            
            $scope.clientLevelList = response.data.data;            
        });

        /*Getting all groups */
        $http.get(apiUrl + '/api/get-firm-groups').success(function(response) {            
            $scope.firmGroups = response.result;
        });

        // Getting all Collectors 
        $http.get(apiUrl + '/api/collector').success(function(res){
            $scope.collectorsList = res.result;
        });

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }; 
        $scope.reloadData = function() {
            var resetPaging = true;
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.searchData = function(){
            $scope.searchFilter = {
                selectedFirmsIds : $scope.selectedFirmsIds,
                selectedSalesPersonsIds : $scope.selectedSalesPersonsIds,
                selectedTermsIds : $scope.selectedTermsIds,
                selectedSourcesId : $scope.selectedSourcesId,
                selectedStatus : $scope.selectedStatus,
                selectedClientLevel : $scope.selectedClientLevel,
                selectedGroups : $scope.selectedGroups,
                selectedCollectors : $scope.selectedCollectors,
                filterByGoldenIds : $scope.filterByGoldenIds,
                filterByCreatedDate : $scope.filterByCreatedDate ? $scope.filterByCreatedDate : false,
                createdDateRange : {
                    startDate : moment($scope.dateObj.createdDateRange.startDate).format('MM-DD-YYYY'),
                    endDate : moment($scope.dateObj.createdDateRange.endDate).format('MM-DD-YYYY')
                }
            };
            localStorage.setItem('datatable_search_filter',JSON.stringify($scope.searchFilter));
            $scope.reloadData();
        };
        
        $scope.clear = function(){
            $scope.selectedFirmsIds = [];
            $scope.selectedSalesPersonsIds = [];
            $scope.selectedTermsIds = [];
            $scope.selectedSourcesId = [];
            $scope.selectedStatus = [];
            $scope.selectedClientLevel = [];
            $scope.selectedGroups = [];
            $scope.selectedCollectors = [];
            $scope.filterByGoldenIds = "";
            $scope.filterByCreatedDate = false;
            $scope.dateObj = {
                createdDateRange : {
                    startDate : moment(),
                    endDate : moment()
                }
            };
            $scope.searchData();
        }

        $scope.getSearchFilter = function(){
            var filter = JSON.parse(localStorage.getItem('datatable_search_filter'));
            if(filter){
                angular.forEach(filter.selectedFirmsIds,function(value, key){
                    $scope.selectedFirmsIds.push(value);
                });
                $scope.selectedSalesPersonsIds = filter.selectedSalesPersonsIds;
                $scope.selectedTermsIds = filter.selectedTermsIds;
                $scope.selectedSourcesId = filter.selectedSourcesId;
                $scope.selectedStatus = filter.selectedStatus;
                $scope.selectedClientLevel = filter.selectedClientLevel;
                $scope.selectedGroups = filter.selectedGroups;
                $scope.selectedCollectors = filter.selectedCollectors;
                $scope.filterByGoldenIds = filter.filterByGoldenIds;
                $scope.filterByCreatedDate = filter.filterByCreatedDate;
                if(filter.createdDateRange){
                    $scope.dateObj.createdDateRange = filter.createdDateRange;
                };
            }   
        }
        $scope.getSearchFilter();

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {

                data.selectedFirmsIds = $scope.selectedFirmsIds.map(function(a){
                    return  a.id;
                });

                data.selectedSalesPersonsIds = $scope.selectedSalesPersonsIds;
                
                data.selectedTermsIds = $scope.selectedTermsIds;

                data.excludeZeroBalDue = $scope.excludeZeroBalDue ? $scope.excludeZeroBalDue : false;

                data.inv_age = $scope.inv_age ? $scope.inv_age : '';

                data.inv_age_filter = $scope.inv_age_filter ? $scope.inv_age_filter : '';

                data.selectedSourcesId = $scope.selectedSourcesId;
                
                data.selectedStatus = $scope.selectedStatus;
                
                data.selectedClientLevel = $scope.selectedClientLevel;

                data.selectedGroups = $scope.selectedGroups;

                data.selectedCollectors = $scope.selectedCollectors;
                
                data.excludeSalesPerson = $scope.excludeSalesPerson ? $scope.excludeSalesPerson : false;

                data.excludeCollecter = $scope.excludeCollecter ? $scope.excludeCollecter : false;

                data.filterByGoldenIds = $scope.filterByGoldenIds;

                data.filterByCreatedDate = $scope.filterByCreatedDate ? $scope.filterByCreatedDate : false;

                data.startDate = moment($scope.dateObj.createdDateRange.startDate).format('YYYY-MM-DD');
                data.endDate = moment($scope.dateObj.createdDateRange.endDate).format('YYYY-MM-DD');

                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                
                angular.forEach(data.columns,function(value, key){
                    data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                });

                if((data.selectedFirmsIds && data.selectedFirmsIds.length > 0) || (data.selectedSalesPersonsIds && data.selectedSalesPersonsIds.length > 0) || (data.selectedTermsIds && data.selectedTermsIds.length > 0) || data.filterByCreatedDate || data.filterByGoldenIds || (data.selectedSourcesId && data.selectedSourcesId.length > 0) || (data.selectedStatus && data.selectedStatus.length > 0) || (data.selectedClientLevel && data.selectedClientLevel.length > 0) || (data.selectedGroups && data.selectedGroups.length) > 0 || (data.selectedCollectors && data.selectedCollectors.length > 0)){
                    $scope.isFilterApplied = true;
                }
                commonFactory.showDataTable('/api/firms/show-data-table', data)
                .success(function(res) {
                    if (res) {
                        $rootScope.showLoader(false);
                        $scope.filterObj = data;
                        callback(res);
                        $('#attfirm').find('thead input').attr('autocomplete', 'off');
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            })
            .withLanguage({
                "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

            })            
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withOption('stateSave', false)
            .withOption('lengthMenu',[[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withOption('order', [0, 'asc'])
            .withOption('createdRow', $scope.compileDatatable)
            .withLightColumnFilter({
                '0': {
                    type: 'text',
                    time: 600
                },
                '1': {
                    type: 'text',
                    time: 600
                },
                '2': {
                    type: 'text',
                    time: 600
                },
                '3': {
                    type: 'text',
                    time: 600
                },
                '12': {
                    type: 'text',
                    time: 600
                }

            });

        $scope.getStatements = function(firm_code){
            var reportModal = $uibModal.open({
                templateUrl: "modules/firms/browse/statement_modal.html", 
                controller: function($scope,firm_code,$uibModalInstance,$sce,$rootScope){
                    
                    var auth_user = $rootScope.auth_user;   
                    $scope.givenUrl = $sce.trustAsResourceUrl( $rootScope.depoHost +"/autologin.php?userid="+auth_user.email+"&password="+auth_user.depo_psd+"&script= mystatement.php?firm_code="+encodeURIComponent(firm_code));  

                    $scope.spinnerLoader= true;
                    $scope.closePopupWindow = function(){
                        $uibModalInstance.close(true);
                    };
                    $scope.iframeLoadedCallBack = function(){
                    // do stuff
                        $scope.spinnerLoader= false;
                        $scope.$apply();
                        console.log("loading Complete");
                    }
 
     
                },
                resolve: {
                    firm_code : function(){
                        return firm_code;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
 
            reportModal.result.then(function () {
            }, function () {
                console.log("Modal Dismissed!!!");
            }); 
        };
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };

        $scope.dtColumns = [
            DTColumnBuilder.newColumn('legacy_id').withOption('defaultContent', '').withTitle('Legacy ID <hr style="margin-top:5px;margin-bottom:5px"> NS ID').withOption("width", "10%").renderWith(function(data,type,full,meta){
                var netSuiteId = '';
                if(full.netSuiteId){
                    netSuiteId = '<br><hr style="margin:5px 0px">'+full.netSuiteId;
                }

                return full.legacy_id + netSuiteId;
            }), 
            DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('Name').withOption("width", "15%").renderWith(function(data,type,full,meta){
                var isStatus = full.STATUS ? true : false; 
                let html = '<a ui-sref="firms.manage.step_one({id : ' + full.id + '})">'+data+'</a>';

                if(data != full.SHORT_NAME){
                    html += '<br><span>'+full.SHORT_NAME+'</span>';
                }

                html += '<span ng-if='+$scope.filterByGoldenIds+'><br>'+full.goldenRecordId+'</span><br><small ng-if='+isStatus+'><b><i>'+full.STATUS+'</i></b></small>';
                return html;
            }),
            DTColumnBuilder.newColumn('ADDR_LINE1').withOption('defaultContent', '').withTitle('Address').withOption("width", "10%").withOption('name', 'ATTORNEY_tbl.ADDR_LINE1'),
            DTColumnBuilder.newColumn('reportingSalesman').withOption('defaultContent', '').withTitle('Account Executive <span class="small">(s)</span>')
            .renderWith(function(data,type,full,meta){ 
                if(full.reporting_salesman.length){
                    var name = [];
                    angular.forEach(full.reporting_salesman,function(value, key){
                        if(value.reptype && value.reptype.name){
                            name[key] = '<u>'+escapeString(value.reptype.name)+'</u>';
                        }
                    });
                    return name.join('<br>');
                }else{
                    return '<a href="update collecter" >';
                }
            }).withOption("width", "10%").withOption('name', 'reportingSalesman.reptype.FIRST_NAME'),
            DTColumnBuilder.newColumn('collecter').withOption('defaultContent', '').withTitle('Collector Person <span class="small">(s)</span>')
            .renderWith(function(data,type,full,meta){ 
                // if($scope.collectorsList.length){
                //     var name = [];
                //     angular.forEach($scope.collectorsList,function(value, key){
                //         if(value && value.id ==full.collector_id ){
                //             name = '<u>'+escapeString(value.name)+'</u>';
                //         }
                //     });
                //     return name;
                // }else{
                //     return '-';
                // }
                var id = full.id
                if(full.collecter.length){
                    var name = [];
                    angular.forEach(full.collecter,function(value, key){
                        if(value.id ==full.collector_id){
                            name[key] = '<u>'+escapeString(value.name)+'</u>';
                        }
                    });
                    return name.join('<br>');
                }else{
                    return '<i title="Assign Collector"  ng-click="assignResource('+ id +',\'collector\')" class="fa fa-2x fa-plus" style="cursor: pointer;"></i>';
                }
            }).withOption("width", "10%").withOption('name', 'collecter.name'),
            DTColumnBuilder.newColumn('YTD_PAID').withOption('defaultContent', '').withTitle('YTD Paid').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('YTD_BILLED').withOption('defaultContent', '').withTitle('YTD Billed').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('PYR_BILLED').withOption('defaultContent', '').withTitle('PYR Billed').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('PYR2_BILLD').withOption('defaultContent', '').withTitle('PYR2 Billed').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('BAL_DUE').withOption('defaultContent', '').withTitle('Bal Due').renderWith($scope.currencyFormat),
            // DTColumnBuilder.newColumn('invoice_over60_count').withOption('defaultContent', '').withTitle('Over 60').renderWith(function(data,type,full,meta){
            //     if(full.invoice_over60_count){
            //         return "Yes";
            //     }else{
            //         return "No";
            //     }
            // }),
            DTColumnBuilder.newColumn('PHONE').withOption('defaultContent', '').withTitle('Tel.No').notSortable().withOption("width", "9%"),
            DTColumnBuilder.newColumn('CITY').withOption('defaultContent', '').withTitle('City').withOption("width", "4%"),
            DTColumnBuilder.newColumn('STATE').withOption('defaultContent', '').withTitle('State').withOption("width", "4%"),
            DTColumnBuilder.newColumn('businessunit.name').withOption('defaultContent', '').withTitle('Business Unit').withOption("width", "3%").withOption('name', 'businessunit.name'),
            DTColumnBuilder.newColumn(null).withTitle('').notSortable().withOption("width", "5%")
            .renderWith(function(data, type, full, meta) {
                var firmName = escape(data.NAME);

                bindButtons = '<div class="btn-group btn-sm" uib-dropdown>' +
                    '<button type="button" class="btn btn-primary btn-sm btn-outline" uib-dropdown-toggle> Action <span class="caret"></span></button>'+
                    '<ul role="menu" uib-dropdown-menu="" style="left: -100px!important;">';
                    
                        if($rootScope.havePermission('attorney_firm','update',full.businessunit.id)){
                            bindButtons +=  '<li uib-tooltip="Edit Firm">'+
                                                '<a class="btn btn-xs btn-default" ui-sref="firms.manage.step_one({id : ' + data.id + '})"><i class="fa fa-pencil"></i> &nbsp; Edit </a>'+
                                            '</li>';                              
                        }else{
                            bindButtons +=  '<li uib-tooltip="View Firm">'+
                                                '<a class="btn btn-xs btn-default" ui-sref="firms.manage.step_one({id : ' + data.id + '})"><i class="fa fa-eye"></i> &nbsp; View </a>'+
                                            '</li>';                        
                        }
                        bindButtons += '<li uib-tooltip="Admins">'+
                            '<a class="btn btn-xs btn-default" ui-sref="firms.manage.ebt({id : ' + data.id + '})"><i class="fa fa-user"></i> &nbsp; Admins </a>'+
                        '</li>'+

                        '<li uib-tooltip="Browse Attorneys">'+
                            '<a class="btn btn-xs btn-default" ui-sref="attorney.browse({firmId : ' + data.id + '})"><i class="fa fa-eye"></i> &nbsp; Attorneys </a>'+
                        '</li>'+

                        '<li uib-tooltip="Show Statements">'+
                            '<a class="btn btn-xs btn-default" ui-sref="statements.print_statements({attId:'+ data.id +'})"><i class="fa fa-book"></i> &nbsp; Statements </a>'+
                        '</li>'+

                        '<li uib-tooltip="Pay">'+
                            '<a class="btn btn-xs btn-default" ng-click="autoLogin(\''+data.NAME_KEY+'\')"><i class="fa fa-money"></i> &nbsp; Pay </a>'+
                        '</li>'+

                        // '<li uib-tooltip="A/R Fax Utility">'+
                        //     '<a class="btn btn-xs btn-default" ng-click="open_A_R_Fax_window(\''+data.NAME_KEY+'\')"><i class="fa fa-fax"></i> &nbsp; A/R Fax Utility </a>'+
                        // '</li>'+

                        '<li uib-tooltip="Browse Firm Invoices">'+
                            '<a class="btn btn-xs btn-default" ng-click="showFirmInvoices(' + data.id + ',\''+firmName+'\')"><i class="fa fa-envelope-o"></i> &nbsp; Invoices </a>'+
                        '</li>'+

                        '<li uib-tooltip="Work In Progress Report">'+
                            '<a class="btn btn-xs btn-default" ui-sref="representative.work_in_progress_by_reporter({attId:'+data.id+'})"><i class="fa fa-line-chart"></i> &nbsp; Work In Progress </a>'+
                        '</li>'+

                        '<li uib-tooltip="A/R Notes">' +
                            '<a class="btn btn-xs btn-default" ui-sref="accountReceivableNotes.browse({attId:'+data.id+'})"><i class="fa fa-pencil-square-o"></i> &nbsp; A/R Notes </a>'+
                        '</li>'+

                        '<li uib-tooltip="Stats Report">' +
                            '<a class="btn btn-xs btn-default"  ng-click="open_STATS_report(' + data.id + ')"><i class="fa fa-file"></i>&nbsp; Stats Report </a>'+
                        '</li>'+

                        '<li uib-tooltip="Job Profit Report">' +
                            '<a class="btn btn-xs btn-default"  ui-sref="index.job_profit({attId:' + data.id + '})"><i class="fa fa-pie-chart"></i>&nbsp; Job Profit </a>'+
                        '</li>'+

                        '<li uib-tooltip="Account Credits Report">' +
                            '<a class="btn btn-xs btn-default"  ui-sref="index.account_credits_report({attId:' + data.id + '})"><i class="fa fa-money"></i>&nbsp; Account Credits </a>'+
                        '</li>';

                        if(data.saleforce_id){ 
                           bindButtons +=  '<li uib-tooltip="SalesForce">' +
                                    '<a class="btn btn-xs btn-default" href="https://nextgen.my.salesforce.com/'+data.saleforce_id+'" target="_blank"><i class="fa fa-cloud"></i>&nbsp; SalesForce </a>'+
                                '</li>';
                        }  
                        bindButtons += '</ul></div>';

                return bindButtons;


            })
        ];

        $scope.showFirmInvoices = function(att_id, firm_name){  
            invoiceFactory.searchData = {
                searchValue : {
                    id : att_id,
                    NAME: unescape(firm_name)
                },
                selectedValue : "BILLING_FIRM"
            };
            $state.go('invoice.browse');
        };
         
        $scope.assignResource = function(firmID, resource_type){
            
            var assignResource = $uibModal.open({
                templateUrl: "modules/firms/browse/assignResource.html",
                controller: 'assignResourceController',
                resolve: {
                    firmID: function () {
                        return firmID;
                    },
                    resource_type : function(){
                        return resource_type;
                    }
                },
                keyboard: false,
                backdrop: false,
                close: function () {

                }
            });


            assignResource.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){

                    $scope.reloadData();
                    $rootScope.showLoader(false);
                }

            });
        };
        /* This function is use for prinnt OPERATION METRICS REPORT */
        $scope.printPDF = function(){
            $rootScope.showLoader(true);

            var data = $scope.filterObj;
            data.printPdf = true;
            data.length = -1;
            
            commonFactory.showDataTable('/api/firms/show-data-table', data)
            .success(function(response) { 
                $rootScope.showLoader(false);
                if(response.error){
                    Notification.error('Something went wrong!');
                } else {

                    // $window.location.href = apiUrl +'/api/download-attorneylist-report?fileName='+response.pdfFilename;
                    $window.open(apiUrl +'/api/download-attorneylist-report?fileName='+response.pdfFilename, '_blank'); 
                }
            })
            .error(function(err)
            {   
                Notification.error('Something went wrong!');
                $rootScope.showLoader(false);
            });
        };
        $scope.showInvoicePop = function showInvoicePop(invoice) {
            console.log("aa")
            invoice = $scope.invData.filter(function(e) {
                return e.INV_NO == invoice;
              });
              
              invoices = [{text: invoice}];
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup_geico.html",
                controller: 'viewGeicoInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }
        $scope.copyDatas= function($event){
           
           
            navigator.clipboard.writeText($event.target.innerText)

        $( "<div>Your text here </div>" ).dialog();
        }
    }]);
