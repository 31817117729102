angular.module('deitz').controller('addSoftwareController', [
    '$scope',
    '$state',
    '$rootScope',
    'codeFactory',
    'commonFactory',
    'Notification',
    'fromModal',
    '$uibModalInstance',
    function (
        $scope,
        $state,
        $rootScope,
        codeFactory,
        commonFactory,
        Notification,
        fromModal,
        $uibModalInstance
    ) {

        $scope.software = {};
        $scope.validations = codeFactory.validations;
        $scope.messages = codeFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Software';

        $scope.closeModal = function () {
            $uibModalInstance.close();
        }
        $scope.addSoftware = function (software, isValid) {
            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                commonFactory.post('/api/software', $scope.software)
                    .success(function (response) {
                        $rootScope.showLoader(false);
                        if (response) {
                            if (response.status == 200) {
                                Notification.success("Software added successfully");

                                if (fromModal == true) {
                                    $uibModalInstance.close(response.result.software.id);
                                } else {
                                    $state.go("code_tables.software_browse");
                                }
                            }
                        }
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

    }]);
