angular.module('deitz')
    .controller('autoInvoicePaymentController', ['$scope', '$rootScope', 'Upload', 'Notification', '$timeout', 'apiUrl', '$http', '$state', '$location', function($scope, $rootScope, Upload, Notification, $timeout, apiUrl, $http, $state, $location) {

        $scope.formSubmit = false;
        $scope.invalidFileType = false;
        $scope.log = "";
        $scope.file_name = "";
        $scope.url = apiUrl;
        $scope.formData = {};

        $http.get(apiUrl + '/api/check/get-current-batch-invoice-check-session').then(function(response) { 
            if(response.data.session.length==0){
                swal({
                    title: "Are you sure ?",
                    text: "<small>You are running Invoice Operations (PO) and will have exclusive control of PO for a 30 minute session to complete the process.<br> Before accepting control, be sure your reports tie-out to the Batch Totals. <br>  Your User information will be tracked and any OTHER USER attempting to run invoice during your session will see your User information.<br> Once your update is completed your exclusive session will automatically be released.<small>",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    html:true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $http.post(apiUrl+'/api/check/start-batch-invoice-check-session')
                        .success(function(response){
                        if(!response.success){
                                swal({
                                    title:response.user.name +"  is already using the invoice operation. If you need to contact them, please email "+response.user.email,
                                    html:true
                                },function(isConfirm){
                                    $location.path('/home');
                                });
                        }
                        else{
                                Notification.success("Your invoice session start successfully");
                                $timeout(function () {
                                    Notification.error("Your invoice session is expired");
                                    $location.path('/home');
                                }, response.session.session_remain_time);
                        }
                        });
                    }
                    else{
                        // $location.path('/home');
                        $state.go('index.home');
                    }
                });
            }
            else{
                if(response.data.session[0].user_id!=$rootScope.auth_user.id){
                    swal({
                        title:response.data.session[0].user.name +"  is already using the invoice operation. If you need to contact them, please email "+response.data.session[0].user.email,
                        html:true
                    });
                    $location.path('/home');
                }
                else{
                    $timeout(function () {
                        Notification.error("Your invoice session is expired");
                        $location.path('/home');
                    }, response.data.session[0].session_remain_time);
                }
            }
        });
        $scope.closeInvoiceSession = function(){
            $http.post(apiUrl+'/api/check/stop-batch-invoice-check-session')
            .success(function(response){
                Notification.success("Invoice session end successfully");
                $location.path('/home');
            });
        }

        $scope.resetForm = function(){
            $scope.invoicePaymentForm.$setPristine(true);
            $scope.invoicePaymentForm.$setUntouched(true);
            $scope.formData.selectedFile = "";
            $scope.file_name = "";
            $scope.formData.application_date = moment();
        }

        $scope.submitForm = function(isValid) {
            $scope.formSubmit = true;
            $scope.file_name = "";
            
            if (isValid && $scope.invoicePaymentForm.selectedFile.$valid && $scope.formData.selectedFile) {
                
                var tmpName = $scope.formData.selectedFile.name.split('.');
                var fileType = tmpName[tmpName.length-1];
                if(fileType != 'xls' && fileType != 'xlsx'){
                    $scope.invalidFileType = true;
                    $timeout(function() {
                    	$scope.invalidFileType = false;
                    }, 3000);
                } else{
                    $rootScope.showLoader(true); 

                    $scope.invalidFileType = false;
                    var application_date = moment($scope.formData.application_date).format('MM/DD/YYYY');
                    Upload.upload({
                        url: 'api/autoInvoicePayment',
                        data: {import_file: $scope.formData.selectedFile, APP_DT : application_date}
                    }).then(function (resp) {
                        $rootScope.showLoader(false);
                        $scope.resetForm();
                        if(resp.data.error){ 
                        	Notification.error(resp.data.result.message);
                        }else{
                        	$scope.file_name = resp.data.result.fileName; 
                        	Notification.success(resp.data.result.message);
                        }
                    }, function (resp) { 
                        $scope.resetForm();
                        Notification.error("File data inappropriate..!");
                        $rootScope.showLoader(false); 
                    });
                }

            }
        };
    }]);